import { useToast } from 'vue-toastification';
import i18n from '../i18n';
import store from '../store';

const toast = useToast();
const { t } = i18n.global;

export default (error) => {
  if (!error.response) {
    toast.error(t('Sorry, something went wrong. Please check your internet connection and try again.'));
  }

  if (error.response) {
    if (error.response.status === 401) {
      const secureApiUser = store.getters.SECURE_API_USER;
      let redirectPath = '/';

      if (secureApiUser) {
        const role = secureApiUser.user.role;
        redirectPath =
          role === 'cms-admin'
            ? '/login-admin'
            : role === 'cms-status-user'
            ? '/login-status'
            : role === 'cms-zzp'
            ? '/login-zzp'
            : role === 'cms-accountant'
            ? '/login-boekhouding'
            : '/';
      }
      // console.log(redirectPath);
      toast.error(t('Sorry, you are not logged in yet, please login first to access this page.'));
      window.location.replace(redirectPath);
    } else {
      toast.error(t('Sorry, something went wrong.'));
    }
  }
  throw error;
};
