import esPromise from 'es6-promise';
import esAssign from 'es6-object-assign';

import { createApp } from 'vue';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import axios from 'axios';
import i18n from '@/i18n';
import VueScrollTo from 'vue-scrollto';
import Toast from 'vue-toastification';
import VueUniversalModal from 'vue-universal-modal';
import { VueCookieNext } from 'vue-cookie-next';
import App from './App';
import router from './router';
import store from './store';
import { addListener as addApiListener } from './services/api';
import apiErrorHandler from './services/api-error-listener';
import 'normalize.css';
import 'vue-toastification/dist/index.css';
import 'vue-universal-modal/dist/index.css';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import './styles/style.scss';
import './services/validation';
import 'vanilla-cookieconsent/dist/cookieconsent';
import './assets/js/cookieconsent-init';

addApiListener((promise) => store.dispatch('ADD_API_CALL', promise));
apiErrorHandler({ store, router });

esPromise.polyfill();
esAssign.polyfill();

const metaManager = createMetaManager();
const app = createApp(App);

app.use(store);
app.use(router);
app.config.globalProperties.axios = axios;
app.use(VueScrollTo);
app.use(Toast);
app.use(VueUniversalModal, {
  teleportTarget: '#modals',
});
app.use(VueCookieNext);
app.use(i18n);
app.use(metaManager);
app.use(metaPlugin);
app.mixin({
  computed: {
    isAdmin() {
      return this.$store.getters.SECURE_API_USER.role === 'cms-admin';
    },
  },
});
app.mount('#app');
