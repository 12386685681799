<template>
  <nav class="menu navbar navbar-expand-lg navbar-light">
    <div class="menu__container container-fluid p-0">
      <div class="menu__brand-toggler">
        <router-link class="menu__brand navbar-brand" :to="`/${currentLanguage}`">Fresh IT Solutions</router-link>

        <button
          id="js-menu-toggler"
          class="navbar-toggler collapsed menu-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#main-naviation-content"
          aria-controls="main-naviation-content"
          @click="toggleMenu"
        >
          <span class="icon-container">
            <span class="icon-container__content"><i class="icon icon-menu-line"></i></span>
          </span>
        </button>
      </div>

      <div
        class="menu__wrapper collapse navbar-collapse"
        :class="{ 'menu__wrapper--admin-menu-visible': cmsAdminMenuIsVisible() }"
        id="main-naviation-content"
      >
        <ul v-if="filteredPagesMainMenu && filteredPagesMainMenu.length > 0" class="menu__list navbar-nav ms-auto">
          <template v-for="page in filteredPagesMainMenu" :key="page.id">
            <li
              v-if="page.children"
              class="nav-item dropdown"
              @mouseover="hoverOverDropdownMenu(page.id)"
              @mouseleave="leaveDropdownMenu(page.id)"
            >
              <a
                class="nav-link dropdown-toggle"
                :class="{ show: activeDropdownMenuItemId === page.id }"
                href="javascript:void(0)"
                id="nav-link-dropdown-1"
                role="button"
                data-bs-toggle="dropdown"
                :aria-expanded="activeDropdownMenuItemId === page.id"
              >
                {{ page.menu_title }}
              </a>
              <ul
                :class="[`dropdown-menu dropdown-menu--${page.url}`, { show: activeDropdownMenuItemId === page.id }]"
                aria-labelledby="nav-link-dropdown-1"
                :data-bs-popper="activeDropdownMenuItemId === page.id ? 'none' : null"
              >
                <li v-for="childPage in page.children" :key="childPage.id">
                  <router-link class="dropdown-item" :to="`/${currentLanguage}/${childPage.full_url}`">{{
                    childPage.menu_title
                  }}</router-link>
                </li>
              </ul>
            </li>
            <li v-else class="nav-item">
              <router-link class="nav-link" exact-path :to="`/${currentLanguage}/${page.full_url}`">{{
                page.menu_title
              }}</router-link>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { useWindowSize } from 'vue-window-size';
import { mapGetters } from 'vuex';
import WithPageInfo from './with-page-info';

export default {
  name: 'Menu',
  mixins: [WithPageInfo],
  setup() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  data() {
    return {
      activeDropdownMenuItemId: null,
      bodyElement: document.body,
    };
  },
  props: {},
  computed: {
    ...mapGetters({
      dutchPages: 'PUBLIC_DUTCH_PAGES',
      englishPages: 'PUBLIC_ENGLISH_PAGES',
      secureApiUser: 'SECURE_API_USER',
    }),
    currentLanguage() {
      return this.$route.path.includes('/en/') || this.$route.fullPath === '/en' ? 'en' : 'nl';
    },
    pages() {
      return this.currentLanguage === 'en' ? this.englishPages : this.dutchPages;
    },
    pagesMainMenu() {
      return this.pages && this.pages.length > 0
        ? this.pages.filter((page) => page.visible && page.visible_in_menu)
        : [];
    },
    filteredPagesMainMenu() {
      const filteredParentPages = this.pagesMainMenu.filter((page) => !page.belongs_to);
      const filteredChildPages = this.pagesMainMenu.filter((page) => page.belongs_to);
      filteredChildPages.forEach((page) => {
        const parentKey = Object.keys(filteredParentPages).find(
          (key) => filteredParentPages[key].id === page.belongs_to
        );

        if (!filteredParentPages[parentKey].children) {
          filteredParentPages[parentKey].children = [];
        }

        if (
          !Object.keys(filteredParentPages[parentKey].children).find(
            (key) => filteredParentPages[parentKey].children[key].id === page.id
          )
        ) {
          filteredParentPages[parentKey].children.push(page);
        }
      });

      return filteredParentPages;
    },
    windowInnerWidth() {
      return window.innerWidth;
    },
  },
  watch: {
    windowWidth() {
      if (this.windowWidth > 992) {
        const mainNavigationElements = document.getElementsByClassName('menu__wrapper');
        const menuTogglerElements = document.getElementsByClassName('menu-toggler');

        this.bodyElement.classList.remove('has-overlay');

        if (mainNavigationElements && mainNavigationElements.length > 0) {
          Array.from(mainNavigationElements).forEach((mainNavigationEl) => {
            mainNavigationEl.classList.remove('show');
          });
        }

        if (menuTogglerElements && menuTogglerElements.length > 0) {
          Array.from(menuTogglerElements).forEach((menuToggle) => {
            if (!menuToggle.classList.contains('collapsed')) {
              menuToggle.classList.add('collapsed');
            }
          });
        }
      }
    },
    $route(to, from) {
      if (to !== from && this.windowWidth < 992) {
        const mainNavigationElements = document.getElementsByClassName('menu__wrapper');
        const menuTogglerElements = document.getElementsByClassName('menu-toggler');

        this.bodyElement.classList.remove('has-overlay');

        if (mainNavigationElements && mainNavigationElements.length > 0) {
          Array.from(mainNavigationElements).forEach((mainNavigationEl) => {
            mainNavigationEl.classList.remove('show');
          });
        }

        if (menuTogglerElements && menuTogglerElements.length > 0) {
          Array.from(menuTogglerElements).forEach((menuToggle) => {
            if (!menuToggle.classList.contains('collapsed')) {
              menuToggle.classList.add('collapsed');
            }
          });
        }
      }
    },
  },
  methods: {
    toggleMenu() {
      if (this.bodyElement.classList.contains('has-overlay')) {
        this.bodyElement.classList.remove('has-overlay');
      } else {
        this.bodyElement.classList.add('has-overlay');
      }
    },
    hoverOverDropdownMenu(dropdownMenuItemId) {
      this.activeDropdownMenuItemId = null;

      if (this.windowWidth > 992) {
        this.activeDropdownMenuItemId = dropdownMenuItemId;
      }
    },
    leaveDropdownMenu() {
      this.activeDropdownMenuItemId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions', '~bootstrap/scss/variables', '~bootstrap/scss/mixins';
@import '../styles/variables';
@import '../styles/mixins';
@import '../styles/_fonts.scss';

.menu-toggler {
  align-items: center;
  background-color: $white;
  border-color: $denim-transparent;
  color: $denim;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  line-height: pxToRem(12px);
  margin-right: pxToRem(30px);
  padding: pxToRem(4px) pxToRem(5.5px);
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  &:focus {
    box-shadow: none;
    outline: 0;
  }

  &:not(.collapsed) {
    background-color: $white;
    padding: pxToRem(9px);

    .icon-container {
      height: pxToRem(30px);
      margin: 0;
      width: pxToRem(30px);

      &__content {
        left: 0;
        top: pxToRem(14px);
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &,
        &::before,
        &::after {
          background-color: $denim;
          height: pxToRem(2px);
          width: pxToRem(30px);
        }

        &::before,
        &::after {
          content: '';
        }

        &::before {
          opacity: 0;
          top: 0;
          transition: top 0.075s ease, opacity 0.075s 0.12s ease;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .icon {
          display: none;
        }
      }
    }
  }

  .icon-container {
    display: inline-block;
    height: pxToRem(30px);
    position: relative;
    width: pxToRem(37px);
    margin-bottom: pxToRem(10px);

    &__content {
      @extend %iconfont;
      @extend %icon-menu-line;
      @extend %trailing-icon-menu-line;
      display: block;
      font-size: pxToRem(37px);
      left: 0;
      top: 0;
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &,
      &::before,
      &::after {
        position: absolute;
        width: pxToRem(37px);
        transition-property: transform;
      }

      &::before,
      &::after {
        display: block;
        transition-duration: 0.15s;
        transition-timing-function: ease;
      }

      &::before {
        margin: 0;
        top: pxToRem(-9px);
        transition: top 0.075s 0.12s ease, opacity 0.075s ease;
      }

      &::after {
        bottom: pxToRem(-9px);
        transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }

      .icon {
        font-size: pxToRem(37px);

        &::before {
          margin: 0;
        }
      }
    }
  }
}

.menu {
  background: $white;
  box-shadow: 0 pxToRem(-7px) pxToRem(30px) 0 rgba(0, 0, 0, 0.4);
  left: 0;
  padding: 0;
  position: relative;
  right: 0;
  top: 0;
  z-index: 1000;

  &__container {
    @include media-breakpoint-up(lg) {
      max-width: pxToRem(1950px);
    }
  }

  &__brand-toggler {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex: 1;

    @include media-breakpoint-up(lg) {
      flex: none;
    }
  }

  &__brand {
    color: $tory-blue;
    font-family: $font-family-brand;
    font-size: pxToRem(20px);
    font-weight: bold;
    letter-spacing: pxToRem(1px);
    margin: pxToRem(15px) pxToRem(30px);
    padding: 0;

    @include media-breakpoint-up(lg) {
      font-size: pxToRem(30px);
    }

    img {
      height: pxToRem(70px);

      @include media-breakpoint-between(lg, xl) {
        height: pxToRem(55px);
      }
    }
  }

  &__wrapper {
    @include media-breakpoint-up(lg) {
      flex: 1;
    }

    @include media-breakpoint-down(lg) {
      border-top: 1px solid $denim-transparent;

      &.show {
        background-color: $white;
        overflow-y: auto;
        height: calc(100vh - 103px);
        height: calc((var(--vh, 1vh) * 100) - 103px);
        display: block;
        left: 0;
        opacity: 1;
        position: fixed;
        width: 100%;
        top: $main-header-height;
      }

      &--admin-menu-visible {
        &.show {
          height: calc(100vh - 153px);
          height: calc((var(--vh, 1vh) * 100) - 153px);
          top: $admin-and-main-header-height;
        }
      }
    }
  }

  &__list {
    padding-right: 0;

    @include media-breakpoint-up(lg) {
      padding-right: pxToRem(40px);
    }
  }

  .nav-item {
    @include media-breakpoint-up(lg) {
      align-items: center;
      display: flex;
      text-align: center;
    }

    & > .nav-link {
      align-items: center;
      color: $catalina-blue;
      display: flex;
      font-size: pxToRem(16px);
      opacity: 1;
      padding: pxToRem(15px) pxToRem(30px);
      position: relative;
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

      @include media-breakpoint-up(lg) {
        padding: pxToRem(37px) pxToRem(20px) pxToRem(36px);
      }

      &::before {
        content: '';
        position: absolute;
        height: pxToRem(1px);
        bottom: 0;
        left: 0;
        background-color: $denim-transparent;
        visibility: visible;
        width: 100%;
        transition: all 0.3s ease-in-out 0s;

        @include media-breakpoint-up(lg) {
          background-color: $malibu;
          height: pxToRem(4px);
          visibility: hidden;
          width: 0%;
        }
      }

      &:hover,
      &.active {
        color: $dodger-blue;

        &::before {
          visibility: visible;
          width: 100%;
        }
      }

      &.show {
        @include media-breakpoint-up(lg) {
          color: $dodger-blue;
        }
      }
    }
  }

  .dropdown {
    .dropdown-toggle {
      &::after {
        border-top: pxToRem(5px) solid;
        border-left: pxToRem(5px) solid transparent;
        border-right: pxToRem(5px) solid transparent;
        margin-left: pxToRem(7px);
        position: absolute;
        right: pxToRem(30px);

        @include media-breakpoint-up(lg) {
          right: pxToRem(5px);
        }
      }

      &.show {
        &::after {
          border-bottom: pxToRem(5px) solid;
          border-top: 0;
        }
      }
    }

    .dropdown-menu {
      border: none;
      border-radius: 0;
      margin: pxToRem(-4px) 0 0;
      padding: 0;
      transition: opacity 0.3s ease-in-out 0s;

      @include media-breakpoint-up(lg) {
        border-top: 4px solid $malibu;
        box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.4), 0 5px 5px -5px rgba(0, 0, 0, 0.4),
          -5px 0 5px -5px rgba(0, 0, 0, 0.4);
        display: block;
        opacity: 0;
        visibility: hidden;

        &.show {
          opacity: 1;
          visibility: visible;
        }
      }

      &--verkoop,
      &--sale,
      &--services {
        @include media-breakpoint-up(lg) {
          column-count: 2;
          column-gap: 0;
          left: auto;
          right: 0;

          li {
            display: inline-block;
            width: 100%;
          }
        }
      }

      &--services {
        @include media-breakpoint-up(lg) {
          column-count: 3;
        }
      }
    }

    .dropdown-item {
      border-bottom: 1px solid $denim-transparent;
      color: $catalina-blue;
      font-size: pxToRem(16px);
      padding: pxToRem(15px) pxToRem(30px);
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

      @include media-breakpoint-up(lg) {
        border: none;
        padding: pxToRem(10px) pxToRem(16px);
      }

      &.active,
      &:active,
      &:hover {
        background-color: $tory-blue-transparent;
        color: $malibu;
      }
    }
  }
}
</style>
