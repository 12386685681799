<template>
  <header class="header" :class="{ 'header--without-images': isHeaderWithoutImages }">
    <div class="header__navigation-bars">
      <admin-menu v-if="cmsAdminMenuIsVisible()" />
      <top-bar />
      <Menu />
    </div>

    <div class="scrolled-menu" :class="{ scrolled: classObject.scrolled, awake: classObject.awake }">
      <div class="header__navigation-bars" :class="{ scrolled: classObject.scrolled, awake: classObject.awake }">
        <admin-menu v-if="cmsAdminMenuIsVisible()" />
        <top-bar />
        <Menu />
      </div>
    </div>

    <div class="header__content-wrapper" :class="{ 'header__content-wrapper--without-images': isHeaderWithoutImages }">
      <div class="header__content-background"></div>
      <div class="circle-bg"></div>
      <div class="circle-bg-2"></div>

      <div class="header__content d-flex container">
        <div class="row g-0">
          <div
            class="header__info d-flex flex-column"
            :class="[
              isHeaderWithoutImages ? 'col-sm-12 col-md-10 col-xl-8 col-xxl-7' : 'col-sm-12 col-md-7',
              {
                'header__info--without-images': isHeaderWithoutImages,
                'header__info--with-subtitle': !!subtitle,
              },
            ]"
          >
            <span v-if="isPreviewPage" class="header__preview-label">{{ $t('Preview page') }}</span>
            <div v-if="newsCategory" class="header__news-category-wrapper mb-1">
              <span class="header__news-category">{{ newsCategoryTitle }}</span>
            </div>
            <span v-if="subtitle" class="header__subtitle">{{ subtitle }}</span>
            <h1 v-if="title" class="header__title mb-3">{{ title }}</h1>
            <p v-if="intro" class="header__intro mb-4">{{ intro }}</p>
            <div v-if="showActionBtn">
              <a
                class="header__action-btn action btn btn-secondary"
                href="javascript:void(0)"
                data-micromodal-trigger="contact-modal"
                @click="openContactModal"
                >{{ actionBtnTitle || $t('Make an appointment') }}</a
              >
            </div>
          </div>
          <div v-if="!showSlider && image" class="header__img-wrapper col-sm-12 col-md-5 align-self-end">
            <div class="header__img-container">
              <img
                class="img-fluid w-100 d-block"
                :src="`https://www.freshitsolutions.nl/api/public/uploads/images/${image}`"
              />
            </div>
          </div>
          <div v-if="showSlider" class="header__slider-wrapper col-sm-12 col-md-5 align-self-end">
            <div v-if="showSlider && sliderImages" class="header__slider custom-swiper custom-swiper--fade">
              <swiper
                :loop="true"
                :autoplay="{
                  delay: 10000,
                }"
                :effect="'creative'"
                class="custom-swiper__container"
              >
                <swiper-slide v-for="slide in sliderImages" :key="slide">
                  <div class="custom-swiper__slide">
                    <img
                      class="img-fluid w-100 d-block"
                      :src="`https://www.freshitsolutions.nl/api/public/uploads/images/${slide.image || slide}`"
                    />
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
    <waves-border v-if="isHeaderWithoutImages" />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { EffectFade, Autoplay, EffectCreative } from 'swiper';
import WithPageInfo from './with-page-info';
import AdminMenu from './cms/admin-menu';
import Menu from './menu';
import TopBar from './top-bar';
import WavesBorder from './waves-border';
import ContactModal from './modal/contact-modal';

SwiperCore.use([EffectFade, EffectCreative, Autoplay]);

export default {
  name: 'Header',
  mixins: [WithPageInfo],
  components: { AdminMenu, Menu, TopBar, WavesBorder, Swiper, SwiperSlide },
  data() {
    return {
      classObject: {
        scrolled: false,
        awake: false,
      },
    };
  },
  props: {
    isPreviewPage: { type: Boolean, default: false },
    newsCategory: Number,
    actionBtnTitle: String,
    showActionBtn: { type: Boolean, default: false },
    subtitle: String,
    title: String,
    intro: String,
    image: String,
    showSlider: { type: Boolean, default: false },
    sliderImages: Array,
  },
  computed: {
    ...mapGetters({
      newsCategories: 'NEWS_CATEGORIES',
    }),
    currentLanguage() {
      return this.$route.path.includes('/en/') || this.$route.fullPath === '/en' ? 'en' : 'nl';
    },
    isHeaderWithoutImages() {
      return !this.image && (!this.sliderImages || (this.sliderImages && this.sliderImages.length === 0));
    },
    newsCategoryTitle() {
      const category = this.newsCategories.find((cat) => cat.id === this.newsCategory);
      return category.title;
    },
  },
  methods: {
    handleScroll() {
      const scrollTop = window.scrollY;

      if (scrollTop > 150) {
        this.classObject.scrolled = true;
      }
      if (scrollTop < 150) {
        this.classObject.scrolled = false;
      }
      if (scrollTop > 350) {
        this.classObject.awake = true;
      }
      if (scrollTop < 350) {
        this.classObject.awake = false;
      }
    },
    async openContactModal() {
      this.$store.dispatch('OPEN_MODAL', {
        component: ContactModal,
        props: {
          title: this.actionBtnTitle || this.$t('Make an appointment'),
        },
      });
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions', '~bootstrap/scss/variables', '~bootstrap/scss/mixins';
@import '../styles/variables';
@import '../styles/mixins';

@keyframes circle-move-1 {
  0% {
    transform: rotate(1deg) translate(2px, 2px);
  }
  50% {
    transform: rotate(-1deg) translate(-2px, -2px);
  }
  100% {
    transform: rotate(1deg) translate(2px, 2px);
  }
}

@keyframes circle-move-2 {
  0% {
    transform: rotate(-1deg) translate(-2px, -2px);
  }
  50% {
    transform: rotate(1deg) translate(2px, 2px);
  }
  100% {
    transform: rotate(-1deg) translate(-2px, -2px);
  }
}

.header {
  min-height: 0;
  position: relative;
  width: 100%;
  z-index: 50;

  @include media-breakpoint-up(md) {
    min-height: pxToRem(600px);
  }

  @include media-breakpoint-up(xl) {
    min-height: pxToRem(620px);
  }

  @include media-breakpoint-up(xxl) {
    min-height: pxToRem(680px);
  }

  @media (min-width: 1530px) {
    max-height: 710px;
  }

  &--without-images {
    min-height: 0;
  }

  &__content-background {
    background: $malibu;
    background: linear-gradient(90deg, $tory-blue 0%, $denim-light 45%, $dodger-blue 70%, $malibu 100%);
    bottom: 0;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  &__content-wrapper {
    display: flex;

    @include media-breakpoint-up(md) {
      min-height: calcPxToRem('-', 600px, $main-header-height);
    }

    @include media-breakpoint-up(xl) {
      min-height: calcPxToRem('-', 620px, $main-header-height);
    }

    @include media-breakpoint-up(xxl) {
      min-height: calcPxToRem('-', 680px, $main-header-height);
    }

    @media (min-width: 1530px) {
      max-height: 710px;
    }

    &--without-images {
      min-height: pxToRem(150px);
    }

    &:not(&--without-images)::after {
      background: url(../assets/images/golf_600.png) no-repeat;
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: contain;
      content: '';
      position: absolute;
      bottom: -1px;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 0;

      @media (min-width: 2050px) {
        background: none;
      }
    }
  }

  &__content {
    padding: 0 0 pxToRem(45px);

    @media (min-width: 767px) {
      padding: 0;
    }

    .row {
      flex: 1;
    }
  }

  &__info {
    justify-content: center;
    padding: pxToRem(15px) pxToRem(30px) pxToRem(25px);
    z-index: 1;

    @media (min-width: 768px) and (max-width: 776px) {
      margin-top: pxToRem(-90px);
    }

    @media (min-width: 777px) {
      margin-top: pxToRem(-110px);
      padding: 0 pxToRem(30px) 0 pxToRem(15px);
    }

    @include media-breakpoint-up(lg) {
      margin-top: pxToRem(-120px);
    }

    @include media-breakpoint-up(xl) {
      margin-top: pxToRem(-145px);
    }

    @include media-breakpoint-up(xxl) {
      margin-top: pxToRem(-168px);
    }

    @media (min-width: 1720px) {
      margin-top: pxToRem(-190px);
    }

    &--without-images {
      margin-top: pxToRem(25px);
      padding: pxToRem(15px) pxToRem(30px) pxToRem(25px);

      @include media-breakpoint-up(md) {
        padding: pxToRem(40px) pxToRem(30px) pxToRem(40px) pxToRem(15px);
      }

      &.header__info--with-subtitle {
        margin-top: pxToRem(20px);
      }
    }
  }

  &__news-category {
    background-color: $dodger-blue;
    border-radius: pxToRem(3px);
    color: $white;
    font-weight: $font-weight-bold;
    margin-right: pxToRem(5px);
    padding: pxToRem(5px) pxToRem(10px);
  }

  &__preview-label {
    color: $white;
    font-size: pxToRem(18px);
    font-weight: $font-weight-bold;
    letter-spacing: pxToRem(3px);
    text-transform: uppercase;

    @media (min-width: 451px) {
      font-size: pxToRem(20px);
    }

    @media (min-width: 767px) and (max-width: 776px) {
      font-size: pxToRem(18px);
    }
  }

  &__subtitle {
    color: $white;
    font-size: pxToRem(18px);
    font-weight: $font-weight-bold;
    letter-spacing: pxToRem(3px);
    opacity: 0.7;

    @media (min-width: 451px) {
      font-size: pxToRem(20px);
    }

    @media (min-width: 767px) and (max-width: 776px) {
      font-size: pxToRem(18px);
    }
  }

  &__title {
    color: $white;

    @include media-breakpoint-up(sm) {
      font-size: $h1-font-size-mobile;
      line-height: $h1-line-height-mobile;
    }

    @media (min-width: 1160px) {
      font-size: $h1-font-size;
      line-height: $h1-line-height;
    }
  }

  &__intro {
    color: rgba(255, 255, 255, 0.7);
    font-size: pxToRem(18px);
    font-weight: $font-weight-normal;
    line-height: 1.5;

    @media (min-width: 1160px) {
      font-size: pxToRem(20px);
    }
  }

  &__img-container,
  &__slider {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      mask-image: url(../assets/images/header-img-mask.png);
      mask-position: bottom center;
      mask-repeat: no-repeat;
      mask-size: 100%;
      position: absolute;
      right: 0;
      top: $main-header-height;
      width: 45vw;
    }

    @include media-breakpoint-up(xl) {
      width: 40vw;
    }

    @include media-breakpoint-up(xxl) {
      width: 33vw;
    }

    @media (min-width: 1750px) {
      width: 27vw;
    }
  }

  .scrolled-menu {
    display: none;

    &.scrolled {
      box-shadow: 0 pxToRem(-7px) pxToRem(30px) 0 rgba(0, 0, 0, 0.4);
      background-color: $white;
      display: block;
      position: fixed;
      margin-top: pxToRem(-230px);
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
    }

    &.awake {
      margin-top: 0;
      transition: 0.3s all ease-out;
    }
  }

  .circle-bg,
  .circle-bg-2 {
    z-index: -1;

    &::after,
    &::before {
      border-radius: 50%;
      content: '';
      opacity: 1;
      position: absolute;
    }
  }

  .circle-bg {
    &::before,
    &::after {
      animation: circle-move-1 5s infinite;
    }

    &::before {
      display: none;
      background: rgba(255, 255, 255, 0.15);
      height: pxToRem(120px);
      right: 30%;
      top: 15%;
      width: pxToRem(120px);

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    &::after {
      background: rgba(255, 255, 255, 0.1);
      height: pxToRem(180px);
      left: 28%;
      opacity: 0.6;
      top: 45%;
      width: pxToRem(180px);
    }
  }

  .circle-bg-2 {
    &::before,
    &::after {
      animation: circle-move-2 5s infinite;
    }

    &::before {
      background: rgba(255, 255, 255, 0.2);
      bottom: 10%;
      height: pxToRem(150px);
      right: 2%;
      width: pxToRem(150px);
    }

    &:after {
      background: rgba(255, 255, 255, 0.05);
      height: pxToRem(100px);
      left: 5%;
      top: 15%;
      width: pxToRem(100px);
    }
  }
}
</style>
