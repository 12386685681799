import emitter from './emitter';

// eslint-disable-next-line no-undef
const cc = initCookieConsent();
const currentLanguage = window.location.pathname.includes('/en/') || window.location.pathname === '/en' ? 'en' : 'nl';
const generalAddThisDescriptionEn = `Cookie set by AddThis, a content sharing widget, to be able to share content via social media. From these shares there will be stored anonymized statistics. Non-personal information in the form of web analytics are also stored.`;
const generalAddThisDescriptionNl = `Cookie ingesteld door AddThis, een widget voor het delen van inhoud, om inhoud via social media te kunnen delen. Van deze deel acties worden geanonimiseerde statistieken opgeslagen. Ook niet-persoonlijke informatie in de vorm van webanalyse wordt opgeslagen.`;

document.addEventListener('DOMContentLoaded', () => {
  const cookieConsentButton = document.getElementById('cookie-consent-btn');
  // eslint-disable-next-line no-unused-expressions
  cookieConsentButton &&
    cookieConsentButton.addEventListener('click', () => {
      cc.showSettings();
    });
});

// run plugin with your configuration
cc.run({
  autoclear_cookies: true,
  cookie_expiration: 30,
  current_lang: currentLanguage,
  hide_from_bots: true,
  page_scripts: true,
  onFirtAction: (user_preferences, cookie) => {
    emitter.emit('cookie-change', cookie);
  },
  onChange: (cookie) => {
    emitter.emit('cookie-change', cookie);
  },
  gui_options: {
    consent_modal: {
      layout: 'bar',
      transition: 'slide',
      swap_buttons: true,
    },
    settings_modal: {
      transition: 'slide', // zoom/slide
    },
  },
  languages: {
    en: {
      consent_modal: {
        title: 'We use cookies',
        description:
          'This website uses cookies to enable essential site functionality as well as marketing, personalization and analytics. You can change the settings or accept the default settings at any time. Cookies related to marketing, personalization and analytics are only set upon approval. <button type="button" data-cc="c-settings" class="cc-link">Customize settings/button>',
        primary_btn: {
          text: 'Accept all',
          role: 'accept_all',
        },
        secondary_btn: {
          text: 'Reject all',
          role: 'accept_necessary',
        },
      },
      settings_modal: {
        title: 'Cookie preferences',
        save_settings_btn: 'Save settings',
        accept_all_btn: 'Accept all',
        reject_all_btn: 'Reject all',
        close_btn_label: 'Close',
        cookie_table_headers: [{ col1: 'Name' }, { col2: 'Expiration' }, { col3: 'Description' }],
        blocks: [
          {
            description:
              'Cookies are set to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.',
          },
          {
            title: 'Strictly necessary cookies',
            description:
              'Strictly necessary cookies help make a website navigable by activating basic functions such as page navigation and access to secure website areas. Without these cookies, the website would not be able to work properly and therefore cannot be disabled.',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true,
            },
            cookie_table: [
              {
                col1: 'cc_cookie',
                col2: '1 month',
                col3: `Stores the cookie consent state for the current domain.`,
              },
              {
                col1: 'XSRF-TOKEN',
                col2: '2 hours',
                col3: `Ensures visitor browsing-security by preventing cross-site request forgery. This cookie is essential for the security of the website and visitor.`,
              },
              {
                col1: 'fresh_it_solutions_session',
                col2: '1 day',
                col3: `Preserves the visitor's session state across page requests.`,
              },
              {
                col1: 'vuex',
                col2: '3 days',
                col3: `Alows user login funcationality and used for state management.`,
              },
              {
                col1: 'VueAppLanguage',
                col2: 'Persistent',
                col3: `Preserves language settings across page requests.`,
              },
              {
                col1: 'CONSENT',
                col2: '2 years',
                col3: `Used to detect if the visitor has accepted the marketing category in the cookie banner. This cookie is necessary for GDPR-compliance of the website. Used by Google and Youtube.`,
              },
              {
                col1: `hashed cookie like 'kbRHPS1Q8aXGaW4UdYT5iwVzUlr9brq0vdvjJKnN'`,
                col2: '2 hours',
                col3: `An identifying parameter generated for each browser session.`,
              },
              {
                col1: '%40%40',
                col2: '2 hours',
                col3: `Preserves the site session across page requests.`,
              },
            ],
          },
          {
            title: 'Statistic cookies',
            description:
              'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you. Their sole purpose is to improve website functions and perform performance measurement.',
            toggle: {
              value: 'statistic',
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              {
                col1: '_ga',
                col2: '2 years',
                col3: `The _ga cookie, installed by Google Analytics, calculates visitor, session, campaign data, and also keeps track of site usage for the site's analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.`,
              },
              {
                col1: '_gid',
                col2: '1 day',
                col3: `Installed by Google Analytics, _gid cookie stores information on how visitors use a website, while also creating an analytics report of the website's performance. Some of the data that are collected include the number of visitors, their source, and the pages they visit anonymously.`,
              },
            ],
          },
          {
            title: 'Marketing cookies',
            description:
              'These cookies may be set through our site by our advertising partners. They can be used by these third parties to build a profile of your interests based on the browsing information they collect from you.',
            toggle: {
              value: 'marketing',
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              {
                col1: 'test_cookie',
                col2: '15 minutes',
                col3: `The test_cookie is set by doubleclick.net and is used to determine if the user's browser supports cookies.`,
              },
              {
                col1: 'VISITOR_INFO1_LIVE',
                col2: '6 months',
                col3: `A cookie set by YouTube to measure bandwidth that determines whether the user gets the new or old player interface.`,
              },
              {
                col1: 'YSC',
                col2: 'session',
                col3: `YSC cookie is set by Youtube and is used to track the views of embedded videos on Youtube pages.`,
              },
              {
                col1: '__atuvc',
                col2: '13 months',
                col3: `AddThis sets this cookie to ensure that the updated count is seen when one shares a page and returns to it, before the share count cache is updated.`,
              },
              {
                col1: '__atuvs',
                col2: '30 minutes',
                col3: `AddThis sets this cookie to ensure that the updated count is seen when one shares a page and returns to it, before the share count cache is updated.`,
              },
              {
                col1: 'loc',
                col2: '13 months',
                col3: generalAddThisDescriptionEn,
              },
              {
                col1: 'mus',
                col2: '1 year',
                col3: generalAddThisDescriptionEn,
              },
              {
                col1: 'na_id',
                col2: '13 months',
                col3: generalAddThisDescriptionEn,
              },
              {
                col1: 'na_tc',
                col2: '2 years',
                col3: generalAddThisDescriptionEn,
              },
              {
                col1: 'notice_gdpr_prefs',
                col2: '5 months',
                col3: generalAddThisDescriptionEn,
              },
              {
                col1: 'notice_preferences',
                col2: '5 months',
                col3: generalAddThisDescriptionEn,
              },
              {
                col1: 'ouid',
                col2: '13 months',
                col3: generalAddThisDescriptionEn,
              },
              {
                col1: 'ssc',
                col2: '13 months',
                col3: generalAddThisDescriptionEn,
              },
              {
                col1: 'sshs',
                col2: '13 months',
                col3: generalAddThisDescriptionEn,
              },
              {
                col1: 'uid',
                col2: '13 months',
                col3: generalAddThisDescriptionEn,
              },
              {
                col1: 'uvc',
                col2: '13 months',
                col3: generalAddThisDescriptionEn,
              },
              {
                col1: 'um',
                col2: '13 months',
                col3: generalAddThisDescriptionEn,
              },
            ],
          },
        ],
      },
    },
    nl: {
      consent_modal: {
        title: 'Wij gebruiken cookies',
        description:
          'Deze website gebruikt cookies om essentiële sitefunctionaliteit mogelijk te maken, evenals marketing, personalisatie en analyses. Je kunt de instellingen op elk moment wijzigen of de standaardinstellingen accepteren. Cookies met betrekking tot marketing, personalisatie en analyses worden pas ingesteld na goedkeuring. <button type="button" data-cc="c-settings" class="cc-link">Instellingen aanpassen</button>',
        primary_btn: {
          text: 'Accepteer alles',
          role: 'accept_all',
        },
        secondary_btn: {
          text: 'Alles afwijzen',
          role: 'accept_necessary',
        },
      },
      settings_modal: {
        title: 'Cookie voorkeuren',
        save_settings_btn: 'Instellingen opslaan',
        accept_all_btn: 'Accepteer alles',
        reject_all_btn: 'Alles afwijzen',
        close_btn_label: 'Sluiten',
        cookie_table_headers: [{ col1: 'Naam' }, { col2: 'Vervalt' }, { col3: 'Beschrijving' }],
        blocks: [
          {
            description:
              'Cookies worden ingesteld om de basisfunctionaliteiten van de website te waarborgen en om uw online ervaring te verbeteren. U kunt voor elke categorie kiezen om u aan/uit te melden wanneer u maar wilt.',
          },
          {
            title: 'Strikt noodzakelijke cookies',
            description:
              'Strikt noodzakelijke cookies helpen de website navigeerbaar te maken door basisfuncties zoals paginanavigatie en toegang tot beveiligde websitegebieden te activeren. Zonder deze cookies zou de website niet goed kunnen werken en kunnen daarom niet worden uitgeschakeld.',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true,
            },
            cookie_table: [
              {
                col1: 'cc_cookie',
                col2: '1 month',
                col3: `Slaat de status op van de gekozen cookie voorkeuren voor het huidige domein.`,
              },
              {
                col1: 'XSRF-TOKEN',
                col2: '2 hours',
                col3: `Zorgt voor veiligheid voor bezoekers bij het browsen door het voorkomen van cross-site request vervalsing. Deze cookie is essentieel voor de veiligheid van de website en bezoeker.`,
              },
              {
                col1: 'fresh_it_solutions_session',
                col2: '1 day',
                col3: `Behoudt de sessiestatus van de bezoeker voor alle paginaverzoeken.`,
              },
              {
                col1: 'vuex',
                col2: '3 days',
                col3: `Maakt inloggen mogelijk en wordt gebruikt voor statusbeheer.`,
              },
              {
                col1: 'VueAppLanguage',
                col2: 'Persistent',
                col3: `Behoudt taalinstellingen voor paginaverzoeken.`,
              },
              {
                col1: 'CONSENT',
                col2: '2 years',
                col3: `Wordt gebruikt om te detecteren of de bezoeker de marketingcategorie in de cookiebanner heeft geaccepteerd. Deze cookie is nodig voor de GDPR-conformiteit van de website. Gebruikt door Google en Youtube.`,
              },
              {
                col1: `hashed cookie like 'kbRHPS1Q8aXGaW4UdYT5iwVzUlr9brq0vdvjJKnN'`,
                col2: '2 hours',
                col3: `Een identificerende parameter die voor elke browsersessie wordt gegenereerd.`,
              },
              {
                col1: '%40%40',
                col2: '2 hours',
                col3: `Behoudt de sitesessie voor alle paginaverzoeken.`,
              },
            ],
          },
          {
            title: 'Prestatie cookies',
            description: `Deze cookies verzamelen informatie over hoe je de website gebruikt, welke pagina's je hebt bezocht en op welke links je hebt geklikt. Alle gegevens zijn geanonimiseerd en kunnen niet worden gebruikt om je te identificeren. Hun enige doel is om de functies van de website te verbeteren en prestatiemetingen uit te voeren.`,
            toggle: {
              value: 'statistic',
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              {
                col1: '_ga',
                col2: '2 years',
                col3: `De _ga-cookie, geïnstalleerd door Google Analytics, berekent bezoekers-, sessie- en campagnegegevens en houdt ook het sitegebruik bij voor het analyserapport van de site. De cookie slaat informatie anoniem op en kent een willekeurig gegenereerd nummer toe om unieke bezoekers te herkennen.`,
              },
              {
                col1: '_gid',
                col2: '1 day',
                col3: `De _gid-cookie, geïnstalleerd door Google Analytics, slaat informatie op over hoe bezoekers een website gebruiken, terwijl er ook een analyserapport wordt gemaakt van de prestaties van de website. Sommige van de gegevens die worden verzameld, omvatten het aantal bezoekers, hun bron en de pagina's die ze anoniem bezoeken.`,
              },
            ],
          },
          {
            title: 'Marketing cookies',
            description:
              'Deze cookies kunnen via onze site worden geplaatst door onze advertentiepartners. Ze kunnen door deze derden worden gebruikt om een ​​profiel van je interesses op te bouwen op basis van de browse-informatie die zij verzamelen.',
            toggle: {
              value: 'marketing',
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              {
                col1: 'test_cookie',
                col2: '15 minutes',
                col3: `De test_cookie wordt ingesteld door doubleclick.net en wordt gebruikt om te bepalen of de browser van de gebruiker cookies ondersteunt.`,
              },
              {
                col1: 'VISITOR_INFO1_LIVE',
                col2: '6 months',
                col3: `Een cookie welke door YouTube is ingesteld om de bandbreedte te meten welke bepaalt of de gebruiker de nieuwe of oude interface krijgt.`,
              },
              {
                col1: 'YSC',
                col2: 'session',
                col3: `YSC-cookie is ingesteld door YouTube en wordt gebruikt om de weergaven van ingesloten video's op YouTube-pagina's bij te houden.`,
              },
              {
                col1: '__atuvc',
                col2: '13 months',
                col3: `AddThis stelt deze cookie in om ervoor te zorgen dat de bijgewerkte telling wordt gezien wanneer iemand een pagina deelt en ernaar terugkeert, voordat de cache voor de telling van de deel acties wordt bijgewerkt.`,
              },
              {
                col1: '__atuvs',
                col2: '30 minutes',
                col3: `AddThis stelt deze cookie in om ervoor te zorgen dat de bijgewerkte telling wordt gezien wanneer iemand een pagina deelt en ernaar terugkeert, voordat de cache voor de telling van de deel acties wordt bijgewerkt.`,
              },
              {
                col1: 'loc',
                col2: '13 months',
                col3: generalAddThisDescriptionNl,
              },
              {
                col1: 'mus',
                col2: '1 year',
                col3: generalAddThisDescriptionNl,
              },
              {
                col1: 'na_id',
                col2: '13 months',
                col3: generalAddThisDescriptionNl,
              },
              {
                col1: 'na_tc',
                col2: '2 years',
                col3: generalAddThisDescriptionNl,
              },
              {
                col1: 'notice_gdpr_prefs',
                col2: '5 months',
                col3: generalAddThisDescriptionNl,
              },
              {
                col1: 'notice_preferences',
                col2: '5 months',
                col3: generalAddThisDescriptionNl,
              },
              {
                col1: 'ouid',
                col2: '13 months',
                col3: generalAddThisDescriptionNl,
              },
              {
                col1: 'ssc',
                col2: '13 months',
                col3: generalAddThisDescriptionNl,
              },
              {
                col1: 'sshs',
                col2: '13 months',
                col3: generalAddThisDescriptionNl,
              },
              {
                col1: 'uid',
                col2: '13 months',
                col3: generalAddThisDescriptionNl,
              },
              {
                col1: 'uvc',
                col2: '13 months',
                col3: generalAddThisDescriptionNl,
              },
              {
                col1: 'um',
                col2: '13 months',
                col3: generalAddThisDescriptionNl,
              },
            ],
          },
        ],
      },
    },
  },
});
