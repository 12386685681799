<template>
  <div class="contact-social-buttons">
    <div class="contact-social-buttons__col">
      <a class="d-flex align-items-center justify-content-center me-3" href="mailto:info@freshitsolutions.nl"
        ><span class="icon icon-envelope"
      /></a>
      <a class="d-flex align-items-center justify-content-center me-3" href="tel:0613454693"
        ><span class="icon icon-phone"
      /></a>
      <a
        class="d-flex align-items-center justify-content-center me-3"
        href="https://www.facebook.com/freshitsolutions.nl/"
        target="_blank"
        ><span class="icon icon-facebook"
      /></a>
    </div>
    <div class="contact-social-buttons__col">
      <a
        class="action btn btn-secondary"
        href="javascript:void(0)"
        data-micromodal-trigger="contact-modal"
        @click="openContactModal"
        >{{ $t('Make an appointment') }}</a
      >
    </div>
  </div>
</template>

<script>
import ContactModal from './modal/contact-modal';

export default {
  name: 'ContactAndSocialButtons',
  methods: {
    async openContactModal() {
      this.$store.dispatch('OPEN_MODAL', {
        component: ContactModal,
        props: {
          title: this.$t('Make an appointment'),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions', '~bootstrap/scss/variables', '~bootstrap/scss/mixins';
@import '../styles/variables';
@import '../styles/mixins';

.contact-social-buttons {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__col {
    &:first-child {
      a {
        background: rgba(255, 255, 255, 0.15);
        border-radius: 50%;
        box-shadow: 0 pxToRem(2px) pxToRem(10px) 0 rgba(0, 0, 0, 0.01),
          0 pxToRem(5px) pxToRem(15px) 0 rgba(0, 0, 0, 0.01);
        color: $white;
        display: block;
        float: left;
        height: pxToRem(50px);
        margin-bottom: pxToRem(15px);
        position: relative;
        text-decoration: none;
        width: pxToRem(50px);

        &:hover {
          background: rgba(255, 255, 255, 0.25);
        }
      }
    }
  }

  .icon {
    &::before {
      margin-right: 0;
    }
  }
}
</style>
