import isEqual from 'lodash.isequal';
import {
  getPageList,
  getPublicPageList,
  getPage,
  createPage,
  editPage,
  deletePage,
  getNextPageId,
  doesPageMenuTitleExist,
} from '../../services/backend';

export default {
  state: {
    pages: null,
    publicDutchPages: null,
    publicEnglishPages: null,
    page: null,
    pageChanges: null,
    nextPageId: null,
    currentPublicPage: null,
    consentedCookieCategories: ['necessary'],
  },
  getters: {
    PAGES: (state) => (state.pages && state.pages.data ? state.pages.data : state.pages),
    PUBLIC_DUTCH_PAGES: (state) =>
      state.publicDutchPages && state.publicDutchPages.data ? state.publicDutchPages.data : state.publicDutchPages,
    PUBLIC_ENGLISH_PAGES: (state) =>
      state.publicEnglishPages && state.publicEnglishPages.data
        ? state.publicEnglishPages.data
        : state.publicEnglishPages,
    PAGE: (state) => ({ ...(state.page || {}), ...state.pageChanges }),
    CURRENT_PUBLIC_PAGE: (state) => state.currentPublicPage,
    NEXT_PAGE_ID: (state) => state.nextPageId,
    CONSENTED_COOKIE_CATEGORIES: (state) => state.consentedCookieCategories,
  },
  mutations: {
    SET_PAGES: (state, pages) => {
      state.pages = Object.freeze(pages);
    },
    SET_PUBLIC_PAGES: (state, publicPages) => {
      state.publicPages = Object.freeze(publicPages);
    },
    SET_PAGE: (state, page) => {
      if (isEqual(page, state.page)) {
        return;
      }

      state.page = page ? { ...page } : null;
      state.pageChanges = {};
    },
    SET_CURRENT_PUBLIC_PAGE: (state, page) => {
      if (isEqual(page, state.currentPublicPage)) {
        return;
      }

      state.currentPublicPage = page ? { ...page } : null;
    },
    SET_PAGE_CHANGES: (state, changes) => {
      state.pageChanges = changes;
    },
    CLEAR_PAGE: (state) => {
      state.page = null;
      state.pageChanges = null;
    },
    CLEAR_CURRENT_PUBLIC_PAGE: (state) => {
      state.currentPublicPage = null;
    },
    SET_NEXT_PAGE_ID: (state, nextPageId) => {
      state.nextPageId = nextPageId;
    },
    SET_PUBLIC_DUTCH_PAGES: (state, publicDutchPages) => {
      state.publicDutchPages = Object.freeze(publicDutchPages);
    },
    SET_PUBLIC_ENGLISH_PAGES: (state, publicEnglishPages) => {
      state.publicEnglishPages = Object.freeze(publicEnglishPages);
    },
    SET_CONSENTED_COOKIE_CATEGORIES: (state, categories) => {
      state.consentedCookieCategories = categories;
    },
  },
  actions: {
    async LOAD_PAGES({ commit }) {
      const { data } = await getPageList();

      commit('SET_PAGES', data);
    },
    async LOAD_PUBLIC_PAGES({ commit }, currentLanguage) {
      const { data } = await getPublicPageList(currentLanguage);

      if (currentLanguage === 'en') {
        commit('SET_PUBLIC_ENGLISH_PAGES', data);
      } else {
        commit('SET_PUBLIC_DUTCH_PAGES', data);
      }
    },
    async CREATE_PAGE(context, page) {
      const { data } = await createPage(page);

      return data;
    },
    async EDIT_PAGE(context, page) {
      const { data } = await editPage(page);

      return data;
    },
    async LOAD_PAGE({ commit }, pageId) {
      const { data } = await getPage(pageId);

      commit('CLEAR_PAGE');
      commit('SET_PAGE', data);

      return data;
    },
    async DELETE_PAGE(context, pageId) {
      const { data } = await deletePage(pageId);

      return data;
    },
    async LOAD_NEXT_PAGE_ID({ commit }) {
      const { data } = await getNextPageId();

      commit('SET_NEXT_PAGE_ID', data);
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_PAGE', null);
    },
    async DOES_PAGE_MENU_TITLE_EXIST(context, menuTitle) {
      const { data } = await doesPageMenuTitleExist(menuTitle);

      return data;
    },
    async LOAD_PUBLIC_PAGE_BY_URL({ commit, getters }, { url, currentLanguage }) {
      const publicPages = currentLanguage === 'en' ? getters.PUBLIC_ENGLISH_PAGES : getters.PUBLIC_DUTCH_PAGES;
      const currentPublicPage = publicPages.find((page) => page.full_url === url);

      commit('CLEAR_CURRENT_PUBLIC_PAGE');
      commit('SET_CURRENT_PUBLIC_PAGE', currentPublicPage);

      return currentPublicPage;
    },
    async SET_CONSENTED_COOKIE_CATEGORIES({ commit }, categories) {
      commit('SET_CONSENTED_COOKIE_CATEGORIES', categories);
    },
    async CLEAR_CURRENT_PUBLIC_PAGE({ commit }) {
      commit('CLEAR_CURRENT_PUBLIC_PAGE', null);
    },
  },
};
