import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      secureApiUser: 'SECURE_API_USER',
    }),
  },
  methods: {
    cmsAdminMenuIsVisible() {
      return this.secureApiUser && this.secureApiUser.user.role === 'cms-admin';
    },
    getCmsPageHeaderInfo() {
      const currentRouteName = this.$route.name;
      let headerInfo = {};

      switch (currentRouteName) {
        case 'login-cms-admin':
          headerInfo = {
            title: this.$t('headerTitleLoginCmsAdmin'),
            intro: this.$t('headerIntroLoginCmsAdmin'),
          };
          break;
        case 'login-cms-accountant':
          headerInfo = {
            title: this.$t('headerTitleLoginCmsAccountant'),
            intro: this.$t('headerIntroLoginCmsAccountant'),
          };
          break;
        case 'login-cms-status-user':
          headerInfo = {
            title: this.$t('headerTitleLoginCmsStatus'),
            intro: this.$t('headerIntroLoginCmsStatus'),
          };
          break;
        case 'login-cms-zzp':
          headerInfo = {
            title: this.$t('headerTitleLoginCmsZzp'),
            intro: this.$t('headerIntroLoginCmsZzp'),
          };
          break;
        case 'dashboard':
          headerInfo = {
            title: this.$t('Pages'),
          };
          break;
        case 'pages':
          headerInfo = {
            title: this.$t('Pages'),
          };
          break;
        case 'addPage':
          headerInfo = {
            title: this.$t('Add page'),
          };
          break;
        case 'editPage':
          headerInfo = {
            title: this.$t('Edit page'),
          };
          break;
        case 'features':
          headerInfo = {
            title: this.$t('Features'),
          };
          break;
        case 'addFeature':
          headerInfo = {
            title: this.$t('Add feature'),
          };
          break;
        case 'editFeature':
          headerInfo = {
            title: this.$t('Edit feature'),
          };
          break;
        case 'services':
          headerInfo = {
            title: this.$t('Services'),
          };
          break;
        case 'addService':
          headerInfo = {
            title: this.$t('Add service'),
          };
          break;
        case 'editService':
          headerInfo = {
            title: this.$t('Edit service'),
          };
          break;
        case 'news':
          headerInfo = {
            title: this.$t('News'),
          };
          break;
        case 'addNews':
          headerInfo = {
            title: this.$t('Add news'),
          };
          break;
        case 'editNews':
          headerInfo = {
            title: this.$t('Edit news'),
          };
          break;
        case 'tips':
          headerInfo = {
            title: this.$t('Tips'),
          };
          break;
        case 'addTip':
          headerInfo = {
            title: this.$t('Add tip'),
          };
          break;
        case 'editTip':
          headerInfo = {
            title: this.$t('Edit tip'),
          };
          break;
        case 'reviews':
          headerInfo = {
            title: this.$t('Reviews'),
          };
          break;
        case 'addReview':
          headerInfo = {
            title: this.$t('Add review'),
          };
          break;
        case 'editReview':
          headerInfo = {
            title: this.$t('Edit review'),
          };
          break;
        case 'customers':
          headerInfo = {
            title: this.$t('Customers'),
          };
          break;
        case 'addCustomer':
          headerInfo = {
            title: this.$t('Add customer'),
          };
          break;
        case 'emails':
          headerInfo = {
            title: this.$t('E-mails'),
          };
          break;
        case 'viewEmail':
          headerInfo = {
            title: this.$t('View e-mail'),
          };
          break;
        case 'emailsPhone':
          headerInfo = {
            title: this.$t('E-mails phone form'),
          };
          break;
        case 'viewEmailPhone':
          headerInfo = {
            title: this.$t('View e-mail phone form'),
          };
          break;
        case 'products':
          headerInfo = {
            title: this.$t('Products'),
          };
          break;
        case 'addProduct':
          headerInfo = {
            title: this.$t('Add product'),
          };
          break;
        case 'editProduct':
          headerInfo = {
            title: this.$t('Edit product'),
          };
          break;
        case 'zzpProducts':
          headerInfo = {
            title: this.$t('Zzp products'),
          };
          break;
        case 'addZzpProduct':
          headerInfo = {
            title: this.$t('Add zzp product'),
          };
          break;
        case 'editZzpProduct':
          headerInfo = {
            title: this.$t('Edit zzp product'),
          };
          break;
        case 'editAccountantDownload':
          headerInfo = {
            title: this.$t('Edit accountant downloads'),
          };
          break;
        case 'statusesNewPc':
          headerInfo = {
            title: this.$t('Statuses new pc'),
          };
          break;
        case 'addStatusNewPc':
          headerInfo = {
            title: this.$t('Add status new pc'),
          };
          break;
        case 'editStatusNewPc':
          headerInfo = {
            title: this.$t('Edit status new pc'),
          };
          break;
        case 'statusesRepair':
          headerInfo = {
            title: this.$t('Statuses repair'),
          };
          break;
        case 'addStatusRepair':
          headerInfo = {
            title: this.$t('Add status repair'),
          };
          break;
        case 'editStatusRepair':
          headerInfo = {
            title: this.$t('Edit status repair'),
          };
          break;
        case 'newsGroups':
          headerInfo = {
            title: this.$t('News groups'),
          };
          break;
        case 'addNewsGroup':
          headerInfo = {
            title: this.$t('Add news group'),
          };
          break;
        case 'editNewsGroup':
          headerInfo = {
            title: this.$t('Edit news group'),
          };
          break;
        case 'newsCategories':
          headerInfo = {
            title: this.$t('News categories'),
          };
          break;
        case 'addNewsCategory':
          headerInfo = {
            title: this.$t('Add news category'),
          };
          break;
        case 'editNewsCategory':
          headerInfo = {
            title: this.$t('Edit news category'),
          };
          break;
        case 'serviceGroups':
          headerInfo = {
            title: this.$t('Service groups'),
          };
          break;
        case 'addServiveGroup':
          headerInfo = {
            title: this.$t('Add service group'),
          };
          break;
        case 'editServiceGroup':
          headerInfo = {
            title: this.$t('Edit service group'),
          };
          break;
        case 'status':
          headerInfo = {
            title: this.$t('Current status new pc and/or repair'),
            subtitle: this.$t('Status'),
          };
          break;
        case 'accountant':
          headerInfo = {
            title: this.$t('You can download administration documents here'),
            subtitle: this.$t('Download'),
          };
          break;
        case 'pageNotFound':
          headerInfo = {
            title: this.$t('Page not found'),
            subtitle: this.$t('Oops!'),
            intro: this.$t(`Sorry, we can't find this page anymore`),
            show_action_btn: false,
            show_slider: false,
          };
          break;
        default:
          headerInfo = {
            news_category: null,
            title: '',
            subtitle: '',
            intro: '',
            show_action_btn: false,
            action_btn_title: '',
            image: '',
            show_slider: false,
            slider_images: [],
          };
      }

      return headerInfo;
    },
  },
};
