<template>
  <div class="admin-menu">
    <nav class="admin-menu__navbar navbar navbar-expand-lg">
      <div class="admin-menu__container container-fluid p-0">
        <div class="admin-menu__brand-toggler">
          <button
            id="js-admin-menu-toggler"
            class="navbar-toggler collapsed menu-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#admin-naviation-content"
            aria-controls="admin-naviation-content"
            @click="toggleAdminMenu"
          >
            <span class="icon-container">
              <span class="icon-container__content"><i class="icon icon-menu-line"></i></span>
            </span>
          </button>
        </div>

        <div class="admin-menu__wrapper collapse navbar-collapse" id="admin-naviation-content">
          <ul v-if="secureApiUser" class="admin-menu__list navbar-nav">
            <li class="nav-item">
              <localized-link class="nav-link" :to="{ name: 'dashboard' }" exact>{{ $t('Dashboard') }}</localized-link>
            </li>

            <li
              class="nav-item dropdown"
              @mouseover="hoverOverDropdownMenu('pages')"
              @mouseleave="leaveDropdownMenu('pages')"
            >
              <a
                class="nav-link dropdown-toggle"
                :class="{ show: activeDropdownMenuItemId === 'pages' }"
                href="#"
                id="nav-link-dropdown-pages"
                role="button"
                data-bs-toggle="dropdown"
                :aria-expanded="activeDropdownMenuItemId === 'pages'"
              >
                {{ $t('Page management') }}
              </a>
              <ul
                class="dropdown-menu"
                :class="{ show: activeDropdownMenuItemId === 'pages' }"
                aria-labelledby="nav-link-dropdown-pages"
                :data-bs-popper="activeDropdownMenuItemId === 'pages' ? 'none' : null"
              >
                <li>
                  <localized-link class="dropdown-item" :to="{ name: 'pages' }" exact>{{ $t('Pages') }}</localized-link>
                </li>
                <li>
                  <localized-link class="dropdown-item" :to="{ name: 'features' }" exact>{{
                    $t('Features')
                  }}</localized-link>
                </li>
                <li>
                  <localized-link class="dropdown-item" :to="{ name: 'serviceGroups' }" exact>{{
                    $t('Service groups')
                  }}</localized-link>
                </li>
                <li>
                  <localized-link class="dropdown-item" :to="{ name: 'services' }" exact>{{
                    $t('Services')
                  }}</localized-link>
                </li>
                <li>
                  <localized-link class="dropdown-item" :to="{ name: 'reviews' }" exact>{{
                    $t('Reviews')
                  }}</localized-link>
                </li>
              </ul>
            </li>

            <li
              class="nav-item dropdown"
              @mouseover="hoverOverDropdownMenu('news')"
              @mouseleave="leaveDropdownMenu('news')"
            >
              <a
                class="nav-link dropdown-toggle"
                :class="{ show: activeDropdownMenuItemId === 'news' }"
                href="#"
                id="nav-link-dropdown-news"
                role="button"
                data-bs-toggle="dropdown"
                :aria-expanded="activeDropdownMenuItemId === 'news'"
              >
                {{ $t('News management') }}
              </a>
              <ul
                class="dropdown-menu"
                :class="{ show: activeDropdownMenuItemId === 'news' }"
                aria-labelledby="nav-link-dropdown-news"
                :data-bs-popper="activeDropdownMenuItemId === 'news' ? 'none' : null"
              >
                <li>
                  <localized-link class="dropdown-item" :to="{ name: 'news' }" exact>{{ $t('News') }}</localized-link>
                </li>
                <li>
                  <localized-link class="dropdown-item" :to="{ name: 'newsCategories' }" exact>{{
                    $t('News categories')
                  }}</localized-link>
                </li>
                <li>
                  <localized-link class="dropdown-item" :to="{ name: 'newsGroups' }" exact>{{
                    $t('News groups')
                  }}</localized-link>
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <localized-link class="nav-link" :to="{ name: 'tips' }" exact>{{ $t('Tips') }}</localized-link>
            </li>
            <li class="nav-item">
              <localized-link class="nav-link" :to="{ name: 'customers' }" exact>{{ $t('Customers') }}</localized-link>
            </li>

            <li
              class="nav-item dropdown"
              @mouseover="hoverOverDropdownMenu('emails')"
              @mouseleave="leaveDropdownMenu('emails')"
            >
              <a
                class="nav-link dropdown-toggle"
                :class="{ show: activeDropdownMenuItemId === 'emails' }"
                href="#"
                id="nav-link-dropdown-emails"
                role="button"
                data-bs-toggle="dropdown"
                :aria-expanded="activeDropdownMenuItemId === 'emails'"
              >
                {{ $t('E-mails') }}
              </a>
              <ul
                class="dropdown-menu"
                :class="{ show: activeDropdownMenuItemId === 'emails' }"
                aria-labelledby="nav-link-dropdown-emails"
                :data-bs-popper="activeDropdownMenuItemId === 'emails' ? 'none' : null"
              >
                <li>
                  <localized-link class="dropdown-item" :to="{ name: 'emails' }" exact>{{
                    $t('E-mails contact')
                  }}</localized-link>
                </li>
                <li>
                  <localized-link class="dropdown-item" :to="{ name: 'emailsPhone' }" exact>{{
                    $t('E-mails phone')
                  }}</localized-link>
                </li>
                <li>
                  <localized-link class="dropdown-item" :to="{ name: 'emailsRemoteAssistance' }" exact>{{
                    $t('E-mails remote assistance')
                  }}</localized-link>
                </li>
              </ul>
            </li>

            <li
              class="nav-item dropdown"
              @mouseover="hoverOverDropdownMenu('products')"
              @mouseleave="leaveDropdownMenu('products')"
            >
              <a
                class="nav-link dropdown-toggle"
                :class="{ show: activeDropdownMenuItemId === 'products' }"
                href="#"
                id="nav-link-dropdown-products"
                role="button"
                data-bs-toggle="dropdown"
                :aria-expanded="activeDropdownMenuItemId === 'products'"
              >
                {{ $t('Products') }}
              </a>
              <ul
                class="dropdown-menu"
                :class="{ show: activeDropdownMenuItemId === 'products' }"
                aria-labelledby="nav-link-dropdown-products"
                :data-bs-popper="activeDropdownMenuItemId === 'products' ? 'none' : null"
              >
                <li>
                  <localized-link class="dropdown-item" :to="{ name: 'products' }" exact>{{
                    $t('Products')
                  }}</localized-link>
                </li>
                <li>
                  <localized-link class="dropdown-item" :to="{ name: 'zzpProducts' }" exact>{{
                    $t('Zzp products')
                  }}</localized-link>
                </li>
              </ul>
            </li>

            <li
              class="nav-item dropdown"
              @mouseover="hoverOverDropdownMenu('statuses')"
              @mouseleave="leaveDropdownMenu('statuses')"
            >
              <a
                class="nav-link dropdown-toggle"
                :class="{ show: activeDropdownMenuItemId === 'statuses' }"
                href="#"
                id="nav-link-dropdown-statuses"
                role="button"
                data-bs-toggle="dropdown"
                :aria-expanded="activeDropdownMenuItemId === 'statuses'"
              >
                {{ $t('Statuses') }}
              </a>
              <ul
                class="dropdown-menu"
                :class="{ show: activeDropdownMenuItemId === 'statuses' }"
                aria-labelledby="nav-link-dropdown-statuses"
                :data-bs-popper="activeDropdownMenuItemId === 'statuses' ? 'none' : null"
              >
                <li>
                  <localized-link class="dropdown-item" :to="{ name: 'statusesNewPc' }" exact>{{
                    $t('Statuses new pc')
                  }}</localized-link>
                </li>
                <li>
                  <localized-link class="dropdown-item" :to="{ name: 'statusesRepair' }" exact>{{
                    $t('Statuses repair')
                  }}</localized-link>
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <localized-link class="nav-link" :to="{ name: 'editAccountantDownload', params: { id: 1 } }" exact>{{
                $t('Accountant')
              }}</localized-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useWindowSize } from 'vue-window-size';

export default {
  name: 'AdminMenu',
  components: {},
  setup() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  data() {
    return {
      activeDropdownMenuItemId: null,
      bodyElement: document.body,
    };
  },
  props: {},
  computed: {
    ...mapGetters({
      secureApiUser: 'SECURE_API_USER',
    }),
    windowInnerWidth() {
      return window.innerWidth;
    },
  },
  watch: {
    windowWidth() {
      if (this.windowWidth > 992) {
        const mainNavigationEl = document.getElementById('admin-naviation-content');
        const adminMenuToggler = document.getElementById('js-admin-menu-toggler');

        this.bodyElement.classList.remove('has-admin-overlay');
        mainNavigationEl.classList.remove('show');

        if (!adminMenuToggler.classList.contains('collapsed')) {
          adminMenuToggler.classList.add('collapsed');
        }
      }
    },
  },
  methods: {
    toggleAdminMenu() {
      if (this.bodyElement.classList.contains('has-admin-overlay')) {
        this.bodyElement.classList.remove('has-admin-overlay');
      } else {
        this.bodyElement.classList.add('has-admin-overlay');
      }
    },
    hoverOverDropdownMenu(dropdownMenuItemId) {
      this.activeDropdownMenuItemId = null;

      if (this.windowWidth > 992) {
        this.activeDropdownMenuItemId = dropdownMenuItemId;
      }
    },
    leaveDropdownMenu() {
      this.activeDropdownMenuItemId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions', '~bootstrap/scss/variables', '~bootstrap/scss/mixins';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/_fonts.scss';

.menu-toggler {
  align-items: center;
  background-color: $black;
  border-color: $denim-transparent;
  color: $white;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  line-height: pxToRem(12px);
  margin-right: pxToRem(30px);
  padding: pxToRem(4px) pxToRem(5.5px);
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  &:focus {
    box-shadow: none;
    outline: 0;
  }

  &:not(.collapsed) {
    background-color: $black;
    padding: pxToRem(9px);

    .icon-container {
      height: pxToRem(30px);
      margin: 0;
      width: pxToRem(30px);

      &__content {
        left: 0;
        top: pxToRem(14px);
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &,
        &::before,
        &::after {
          background-color: $white;
          height: pxToRem(2px);
          width: pxToRem(30px);
        }

        &::before,
        &::after {
          content: '';
        }

        &::before {
          opacity: 0;
          top: 0;
          transition: top 0.075s ease, opacity 0.075s 0.12s ease;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .icon {
          display: none;
        }
      }
    }
  }

  .icon-container {
    display: inline-block;
    height: pxToRem(30px);
    position: relative;
    width: pxToRem(37px);
    margin-bottom: pxToRem(10px);

    &__content {
      @extend %iconfont;
      @extend %icon-menu-line;
      @extend %trailing-icon-menu-line;
      display: block;
      font-size: pxToRem(37px);
      left: 0;
      top: 0;
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &,
      &::before,
      &::after {
        position: absolute;
        width: pxToRem(37px);
        transition-property: transform;
      }

      &::before,
      &::after {
        display: block;
        transition-duration: 0.15s;
        transition-timing-function: ease;
      }

      &::before {
        margin: 0;
        top: pxToRem(-9px);
        transition: top 0.075s 0.12s ease, opacity 0.075s ease;
      }

      &::after {
        bottom: pxToRem(-9px);
        transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }

      .icon {
        font-size: pxToRem(37px);

        &::before {
          margin: 0;
        }
      }
    }
  }
}

.admin-menu {
  background: $black;
  box-shadow: 0 pxToRem(-7px) pxToRem(30px) 0 rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 1050;

  // @include media-breakpoint-up(lg) {
  //   max-width: pxToRem(1920px);
  // }

  &__navbar {
    padding: 0;
  }

  &__container {
    flex-wrap: wrap;
  }

  &__wrapper {
    @include media-breakpoint-down(lg) {
      border-top: 1px solid $tory-blue;

      &.show {
        background-color: $black;
        overflow-y: auto;
        height: calc(100vh - 50px);
        height: calc((var(--vh, 1vh) * 100) - 50px);
        display: block;
        left: 0;
        opacity: 1;
        position: fixed;
        width: 100%;
        top: $admin-menu-height;
      }
    }
  }

  &__list {
    padding-right: 0;
  }

  .nav-item {
    & > .nav-link {
      align-items: center;
      color: $white;
      display: flex;
      font-size: pxToRem(16px);
      opacity: 1;
      padding: pxToRem(15px) pxToRem(30px);
      position: relative;
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

      @include media-breakpoint-up(lg) {
        padding: pxToRem(11px) pxToRem(20px) pxToRem(12px);
      }

      &::before {
        content: '';
        position: absolute;
        height: pxToRem(1px);
        bottom: 0;
        left: 0;
        background-color: $denim-transparent;
        visibility: visible;
        width: 100%;
        transition: all 0.3s ease-in-out 0s;

        @include media-breakpoint-up(lg) {
          background-color: $malibu;
          height: pxToRem(4px);
          visibility: hidden;
          width: 0%;
        }
      }

      &:hover,
      &.active {
        color: $white;

        &::before {
          visibility: visible;
          width: 100%;
        }
      }
    }
  }

  .dropdown {
    .dropdown-toggle {
      &::after {
        border-top: pxToRem(5px) solid;
        border-left: pxToRem(5px) solid transparent;
        border-right: pxToRem(5px) solid transparent;
        margin-left: pxToRem(7px);
        position: absolute;
        right: pxToRem(30px);

        @include media-breakpoint-up(lg) {
          right: pxToRem(5px);
        }
      }

      &.show {
        &::after {
          border-bottom: pxToRem(5px) solid;
          border-top: 0;
        }
      }
    }

    .dropdown-menu {
      background: $black;
      border: none;
      border-radius: 0;
      margin: pxToRem(-4px) 0 0;
      padding: 0;
      transition: opacity 0.3s ease-in-out 0s;

      @include media-breakpoint-up(lg) {
        border-top: 4px solid $malibu;
        box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.4), 0 5px 5px -5px rgba(0, 0, 0, 0.4),
          -5px 0 5px -5px rgba(0, 0, 0, 0.4);
        display: block;
        opacity: 0;
        visibility: hidden;

        &.show {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .dropdown-item {
      border-bottom: 1px solid $denim-transparent;
      color: $white;
      font-size: pxToRem(16px);
      padding: pxToRem(15px) pxToRem(30px);
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

      @include media-breakpoint-up(lg) {
        border: none;
        padding: pxToRem(10px) pxToRem(16px);
      }

      &.active,
      &:active,
      &:hover {
        background-color: $tory-blue;
        color: $white;
      }
    }
  }
}
</style>
