import { h, resolveComponent } from 'vue';
import { createWebHistory } from 'vue-router';
import { useToast } from 'vue-toastification';
import { createLangRouter } from 'vue-lang-router';
import i18n from '../i18n';
import store from '../store';
import translations from '../lang/translations';
import localizedURLs from '../lang/localized-urls';

const LoginCms = () => import(/* webpackChunkName: "login-cms" */ '../views/cms/login-cms');
const Page = () => import(/* webpackChunkName: "page" */ '../views/page');
const NewsDetail = () => import(/* webpackChunkName: "news-detail" */ '../views/news-detail');
const TipDetail = () => import(/* webpackChunkName: "tip-detail" */ '../views/tip-detail');
const ContactPage = () => import(/* webpackChunkName: "contact-page" */ '../views/contact-page');
const ProductDetail = () => import(/* webpackChunkName: "product-detail" */ '../views/product-detail');
const Pages = () => import(/* webpackChunkName: "pages-overview" */ '../views/cms/cms-admin/page/pages-overview');
const AddEditPage = () => import(/* webpackChunkName: "add-edit-page" */ '../views/cms/cms-admin/page/add-edit-page');
const Status = () => import(/* webpackChunkName: "status" */ '../views/cms/cms-status-user/status');
const ZZP = () => import(/* webpackChunkName: "zzp" */ '../views/cms/cms-zzp/zzp');
const Accountant = () => import(/* webpackChunkName: "accountant" */ '../views/cms/cms-accountant/accountant');
const Features = () =>
  import(/* webpackChunkName: "features-overview" */ '../views/cms/cms-admin/feature/features-overview');
const AddEditFeature = () =>
  import(/* webpackChunkName: "add-edit-feature" */ '../views/cms/cms-admin/feature/add-edit-feature');
const Services = () =>
  import(/* webpackChunkName: "services-overview" */ '../views/cms/cms-admin/service/services-overview');
const AddEditService = () =>
  import(/* webpackChunkName: "add-edit-service" */ '../views/cms/cms-admin/service/add-edit-service');
const News = () => import(/* webpackChunkName: "news-overview" */ '../views/cms/cms-admin/news/news-overview');
const AddEditNews = () => import(/* webpackChunkName: "add-edit-news" */ '../views/cms/cms-admin/news/add-edit-news');
const Tips = () => import(/* webpackChunkName: "tip-overview" */ '../views/cms/cms-admin/tip/tips-overview');
const AddEditTip = () => import(/* webpackChunkName: "add-edit-tip" */ '../views/cms/cms-admin/tip/add-edit-tip');
const Reviews = () =>
  import(/* webpackChunkName: "reviews-overview" */ '../views/cms/cms-admin/review/reviews-overview');
const AddEditReview = () =>
  import(/* webpackChunkName: "add-edit-review" */ '../views/cms/cms-admin/review/add-edit-review');
const Customers = () =>
  import(/* webpackChunkName: "customer-overview" */ '../views/cms/cms-admin/customer/customer-overview');
const AddCustomer = () => import(/* webpackChunkName: "add-customer" */ '../views/cms/cms-admin/customer/add-customer');
const Emails = () => import(/* webpackChunkName: "email-overview" */ '../views/cms/cms-admin/email/email-overview');
const ViewEmail = () => import(/* webpackChunkName: "view-email" */ '../views/cms/cms-admin/email/view-email');
const EmailsPhone = () =>
  import(/* webpackChunkName: "email-phone-overview" */ '../views/cms/cms-admin/email-phone/email-phone-overview');
const ViewEmailPhone = () =>
  import(/* webpackChunkName: "view-email-phone" */ '../views/cms/cms-admin/email-phone/view-email-phone');
const EmailsRemoteAssistance = () =>
  import(
    /* webpackChunkName: "email-remote-assistance-overview" */ '../views/cms/cms-admin/email-remote-assistance/email-remote-assistance-overview'
  );
const ViewEmailRemoteAssistance = () =>
  import(
    /* webpackChunkName: "view-email-remote-assistance" */ '../views/cms/cms-admin/email-remote-assistance/view-email-remote-assistance'
  );
const Products = () =>
  import(/* webpackChunkName: "product-overview" */ '../views/cms/cms-admin/product/product-overview');
const AddEditProduct = () =>
  import(/* webpackChunkName: "add-edit-product" */ '../views/cms/cms-admin/product/add-edit-product');
const ZzpProducts = () =>
  import(/* webpackChunkName: "zzp-product-overview" */ '../views/cms/cms-admin/zzp-product/zzp-product-overview');
const AddEditZzpProduct = () =>
  import(/* webpackChunkName: "add-edit-zzp-product" */ '../views/cms/cms-admin/zzp-product/add-edit-zzp-product');
const EditAccountantDownload = () =>
  import(
    /* webpackChunkName: "edit-accountant-download" */ '../views/cms/cms-admin/accountant-download/edit-accountant-download'
  );
const StatusesNewPc = () =>
  import(
    /* webpackChunkName: "status-new-pc-overview" */ '../views/cms/cms-admin/status-new-pc/status-new-pc-overview'
  );
const AddEditStatusNewPc = () =>
  import(
    /* webpackChunkName: "add-edit-status-new-pc" */ '../views/cms/cms-admin/status-new-pc/add-edit-status-new-pc'
  );
const StatusesRepair = () =>
  import(
    /* webpackChunkName: "status-repair-overview" */ '../views/cms/cms-admin/status-repair/status-repair-overview'
  );
const AddEditStatusRepair = () =>
  import(
    /* webpackChunkName: "add-edit-status-repair" */ '../views/cms/cms-admin/status-repair/add-edit-status-repair'
  );
const NewsGroupOverview = () =>
  import(/* webpackChunkName: "news-group-overview" */ '../views/cms/cms-admin/news-group/news-group-overview');
const AddEditNewsGroup = () =>
  import(/* webpackChunkName: "add-edit-news-group" */ '../views/cms/cms-admin/news-group/add-edit-news-group');
const NewsCategoryOverview = () =>
  import(
    /* webpackChunkName: "news-category-overview" */ '../views/cms/cms-admin/news-category/news-category-overview'
  );
const AddEditNewsCategory = () =>
  import(
    /* webpackChunkName: "add-edit-news-category" */ '../views/cms/cms-admin/news-category/add-edit-news-category'
  );
const ServiceGroupOverview = () =>
  import(
    /* webpackChunkName: "service-group-overview" */ '../views/cms/cms-admin/service-group/service-group-overview'
  );
const AddEditServiceGroup = () =>
  import(
    /* webpackChunkName: "add-edit-service-group" */ '../views/cms/cms-admin/service-group/add-edit-service-group'
  );
const NewsOverview = () => import(/* webpackChunkName: "news-overview" */ '../views/news-overview');
const PageNotFound = () => import(/* webpackChunkName: "page-not-found" */ '../views/page-not-found');
const TipOverview = () => import(/* webpackChunkName: "tip-overview" */ '../views/tip-overview');
const ProductOverview = () => import(/* webpackChunkName: "product-overview" */ '../views/product-overview');
const { t } = i18n.global;
const toast = useToast();

const clearAll = async () => {
  await store.dispatch('CLEAR_CURRENT_PUBLIC_PAGE');
  await store.dispatch('CLEAR_CURRENT_PUBLIC_NEWS_ITEM');
  await store.dispatch('CLEAR_CURRENT_PUBLIC_TIP');
  await store.dispatch('CLEAR_CURRENT_PUBLIC_PRODUCT');
};

const checkAndLoadPage = async (fullPathWithoutLeadingSlash, next, currentLanguage) => {
  const publicPages = currentLanguage === 'en' ? store.getters.PUBLIC_ENGLISH_PAGES : store.getters.PUBLIC_DUTCH_PAGES;
  const doesPageExist = !!publicPages.find((page) => page.full_url === fullPathWithoutLeadingSlash);

  if (doesPageExist) {
    await store.dispatch('LOAD_PUBLIC_PAGE_BY_URL', { url: fullPathWithoutLeadingSlash, currentLanguage });
  } else {
    next({ name: 'pageNotFound' });
  }
};

const loadPage = async (to, next) => {
  const currentLanguage = to.path.includes('/en/') || to.fullPath === '/en' ? 'en' : 'nl';
  const fullPahtWithoutParams = to.fullPath.split('?')[0];
  const fullPath =
    !fullPahtWithoutParams ||
    fullPahtWithoutParams === '' ||
    fullPahtWithoutParams === '/' ||
    fullPahtWithoutParams === '/nl' ||
    fullPahtWithoutParams === '/nl/'
      ? '/home-nl'
      : fullPahtWithoutParams === '/en' || fullPahtWithoutParams === '/en/'
      ? '/home-en'
      : fullPahtWithoutParams;
  const fullPathWithoutLanguage = fullPath.includes('/en/') || fullPath.includes('/nl/') ? fullPath.slice(3) : fullPath;
  const fullPathWithoutLeadingSlash = fullPathWithoutLanguage.replace(/^\/+/g, '');

  await checkAndLoadPage(fullPathWithoutLeadingSlash, next, currentLanguage);
};

const routes = [
  // {
  //   path: '/error',
  //   name: 'error',
  //   component: ErrorPage,
  //   meta: { publicAccess: true },
  //   beforeEnter(to, from, next) {
  //     if (store.getters.API_USER !== null) {
  //       next({ name: 'home' });
  //     } else {
  //       next();
  //     }
  //   },
  // },

  // Login Routes
  {
    path: '/login-admin',
    name: 'login-cms-admin',
    component: LoginCms,
    meta: { publicAccess: true },
    beforeEnter(to, from, next) {
      const redirect = to.query.redirect || '';
      if (redirect.includes('/login-admin')) {
        next({ name: 'login-cms-admin' });
        return;
      }

      if (store.getters.SECURE_API_USER) {
        if (store.getters.SECURE_API_USER.user.role === 'cms-admin') {
          next({ name: 'cms' });
        } else {
          const homePath = to.path.includes('/en/') ? '/en' : '/nl';
          toast.error(
            t(
              'You are already logged in with another account with another role, please logout first to be able to login with another account.'
            )
          );
          next({ path: homePath });
        }
      }

      next();
    },
  },
  {
    path: '/login-status',
    name: 'login-cms-status-user',
    component: LoginCms,
    meta: { publicAccess: true },
    beforeEnter(to, from, next) {
      const redirect = to.query.redirect || '';
      if (redirect.includes('/login-status')) {
        next({ name: 'login-cms-status-user' });
        return;
      }

      if (store.getters.SECURE_API_USER) {
        if (store.getters.SECURE_API_USER.user.role === 'cms-status-user') {
          next({ name: 'status' });
        } else {
          const homePath = to.path.includes('/en/') ? '/en' : '/nl';
          toast.error(
            t(
              'You are already logged in with another account with another role, please logout first to be able to login with another account.'
            )
          );
          next({ path: homePath });
        }
      }

      next();
    },
  },
  {
    path: '/login-zzp',
    name: 'login-cms-zzp',
    component: LoginCms,
    meta: { publicAccess: true },
    beforeEnter(to, from, next) {
      const redirect = to.query.redirect || '';
      if (redirect.includes('/login-zzp')) {
        next({ name: 'login-cms-zzp' });
        return;
      }

      if (store.getters.SECURE_API_USER) {
        if (store.getters.SECURE_API_USER.user.role === 'cms-zzp') {
          next({ name: 'zzp' });
        } else {
          const homePath = to.path.includes('/en/') ? '/en' : '/nl';
          toast.error(
            t(
              'You are already logged in with another account with another role, please logout first to be able to login with another account.'
            )
          );
          next({ path: homePath });
        }
      }

      next();
    },
  },
  {
    path: '/login-boekhouding',
    name: 'login-cms-accountant',
    component: LoginCms,
    meta: { publicAccess: true },
    beforeEnter(to, from, next) {
      const redirect = to.query.redirect || '';
      if (redirect.includes('/login-boekhouding')) {
        next({ name: 'login-cms-accountant' });
        return;
      }

      if (store.getters.SECURE_API_USER) {
        if (store.getters.SECURE_API_USER.user.role === 'cms-accountant') {
          next({ name: 'accountant' });
        } else {
          const homePath = to.path.includes('/en/') ? '/en' : '/nl';
          toast.error(
            t(
              'You are already logged in with another account with another role, please logout first to be able to login with another account.'
            )
          );
          next({ path: homePath });
        }
      }

      next();
    },
  },
  {
    path: '/uitloggen',
    name: 'logout',
    meta: { publicAccess: true },
    async beforeEnter(to, from, next) {
      try {
        const result = await store.dispatch('LOGOUT');

        if (!result) {
          next({ name: 'home' });
        }

        next({ path: result.data.login_redirect });
      } catch (e) {
        const errorMessage = e.response ? e.response.data.message : e.message;

        toast.error(errorMessage);
      }
    },
  },

  // Routes Cms Admin
  {
    path: '/cougarXr7',
    name: 'cms',
    meta: { publicAccess: false, role: 'cms-admin' },
    async beforeEnter(to, from, next) {
      next({ name: 'dashboard' });
    },
  },
  {
    path: '/cougarXr7/dashboard',
    component: Pages,
    meta: { publicAccess: false, role: 'cms-admin' },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Pages,
        async beforeEnter(to, from, next) {
          await store.dispatch('LOAD_PAGES');
          next();
        },
        meta: { isHomePage: true },
      },
      {
        path: '*',
        redirect: '/cougarXr7/dashboard',
      },
    ],
  },
  {
    path: `/cougarXr7/pagina's`,
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'pages',
        component: Pages,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.dispatch('LOAD_PAGES');
          next();
        },
      },
      {
        path: 'toevoegen',
        name: 'addPage',
        component: AddEditPage,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.commit('SET_PAGE', null);
          await store.commit('SET_PAGE_CHANGES', {});
          await store.dispatch('LOAD_NEXT_PAGE_ID');
          await store.dispatch('LOAD_PAGES');
          next();
        },
      },
      {
        path: 'wijzigen/:id',
        name: 'editPage',
        component: AddEditPage,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_PAGE', null);
            await store.commit('SET_PAGE_CHANGES', {});
            await store.commit('SET_NEXT_PAGE_ID', null);
            await store.dispatch('LOAD_PAGE', to.params.id);
            await store.dispatch('LOAD_PAGES');
            next();
          } catch (e) {
            toast.error(t('Page not found'));
            next({ name: 'pages' });
          }
        },
      },
      {
        path: '*',
        redirect: `/cougarXr7/pagina's`,
      },
    ],
  },
  {
    path: '/cougarXr7/preview/pagina/:id',
    name: 'previewPage',
    component: Page,
    meta: { publicAccess: false, role: 'cms-admin' },
    async beforeEnter(to, from, next) {
      await store.dispatch('LOAD_PAGE', to.params.id);

      next();
    },
  },
  {
    path: '/cougarXr7/kenmerken',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'features',
        component: Features,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.dispatch('LOAD_FEATURES');
          next();
        },
      },
      {
        path: 'toevoegen',
        name: 'addFeature',
        component: AddEditFeature,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.commit('SET_FEATURE', null);
          await store.commit('SET_FEATURE_CHANGES', {});
          await store.dispatch('LOAD_FEATURES');
          next();
        },
      },
      {
        path: 'wijzigen/:id',
        name: 'editFeature',
        component: AddEditFeature,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_FEATURE', null);
            await store.commit('SET_FEATURE_CHANGES', {});
            await store.dispatch('LOAD_FEATURE', to.params.id);
            await store.dispatch('LOAD_FEATURES');
            next();
          } catch (e) {
            toast.error(t('Feature not found'));
            next({ name: 'features' });
          }
        },
      },
      {
        path: '*',
        redirect: '/cougarXr7/kenmerken',
      },
    ],
  },
  {
    path: '/cougarXr7/services',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'services',
        component: Services,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.dispatch('LOAD_SERVICES');
          next();
        },
      },
      {
        path: 'toevoegen',
        name: 'addService',
        component: AddEditService,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.commit('SET_SERVICE', null);
          await store.commit('SET_SERVICE_CHANGES', {});
          await store.dispatch('LOAD_SERVICES');
          next();
        },
      },
      {
        path: 'wijzigen/:id',
        name: 'editService',
        component: AddEditService,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_SERVICE', null);
            await store.commit('SET_SERVICE_CHANGES', {});
            await store.dispatch('LOAD_SERVICE', to.params.id);
            await store.dispatch('LOAD_SERVICES');
            next();
          } catch (e) {
            toast.error(t('Service not found'));
            next({ name: 'services' });
          }
        },
      },
      {
        path: '*',
        redirect: '/cougarXr7/services',
      },
    ],
  },
  {
    path: '/cougarXr7/nieuws',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'news',
        component: News,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.dispatch('LOAD_NEWS');
          next();
        },
      },
      {
        path: 'toevoegen',
        name: 'addNews',
        component: AddEditNews,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.commit('SET_NEWS_ITEM', null);
          await store.commit('SET_NEWS_CHANGES', {});
          await store.dispatch('LOAD_NEXT_NEWS_ID');
          await store.dispatch('LOAD_NEWS');
          next();
        },
      },
      {
        path: 'wijzigen/:id',
        name: 'editNews',
        component: AddEditNews,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_NEWS_ITEM', null);
            await store.commit('SET_NEWS_CHANGES', {});
            await store.commit('SET_NEXT_NEWS_ID', null);
            await store.dispatch('LOAD_NEWS_ITEM', to.params.id);
            await store.dispatch('LOAD_NEWS');
            next();
          } catch (e) {
            toast.error(t('News item not found'));
            next({ name: 'news' });
          }
        },
      },
      {
        path: '*',
        redirect: `/cougarXr7/nieuws`,
      },
    ],
  },
  {
    path: '/cougarXr7/preview/nieuws/:id',
    name: 'previewNews',
    component: NewsDetail,
    meta: { publicAccess: false, role: 'cms-admin' },
    async beforeEnter(to, from, next) {
      await store.dispatch('LOAD_NEWS_ITEM', to.params.id);

      next();
    },
  },
  {
    path: '/cougarXr7/tips',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'tips',
        component: Tips,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.dispatch('LOAD_TIPS');
          next();
        },
      },
      {
        path: 'toevoegen',
        name: 'addTip',
        component: AddEditTip,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.commit('SET_TIP', null);
          await store.commit('SET_TIP_CHANGES', {});
          await store.dispatch('LOAD_NEXT_TIP_ID');
          await store.dispatch('LOAD_TIPS');
          next();
        },
      },
      {
        path: 'wijzigen/:id',
        name: 'editTip',
        component: AddEditTip,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_TIP', null);
            await store.commit('SET_TIP_CHANGES', {});
            await store.commit('SET_NEXT_TIP_ID', null);
            await store.dispatch('LOAD_TIP', to.params.id);
            await store.dispatch('LOAD_TIPS');
            next();
          } catch (e) {
            toast.error(t('Tip not found'));
            next({ name: 'tips' });
          }
        },
      },
      {
        path: '*',
        redirect: '/cougarXr7/tips',
      },
    ],
  },
  {
    path: '/cougarXr7/preview/tip/:id',
    name: 'previewTip',
    component: TipDetail,
    meta: { publicAccess: false, role: 'cms-admin' },
    async beforeEnter(to, from, next) {
      await store.dispatch('LOAD_TIP', to.params.id);

      next();
    },
  },
  {
    path: '/cougarXr7/reviews',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'reviews',
        component: Reviews,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.dispatch('LOAD_REVIEWS');
          next();
        },
      },
      {
        path: 'toevoegen',
        name: 'addReview',
        component: AddEditReview,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.commit('SET_REVIEW', null);
          await store.commit('SET_REVIEW_CHANGES', {});
          await store.dispatch('LOAD_REVIEWS');
          next();
        },
      },
      {
        path: 'wijzigen/:id',
        name: 'editReview',
        component: AddEditReview,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_REVIEW', null);
            await store.commit('SET_REVIEW_CHANGES', {});
            await store.dispatch('LOAD_REVIEW', to.params.id);
            await store.dispatch('LOAD_REVIEWS');
            next();
          } catch (e) {
            toast.error(t('Review not found'));
            next({ name: 'reviews' });
          }
        },
      },
      {
        path: '*',
        redirect: '/cougarXr7/reviews',
      },
    ],
  },
  {
    path: '/cougarXr7/klanten',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'customers',
        component: Customers,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.dispatch('LOAD_CUSTOMERS');
          next();
        },
      },
      {
        path: 'toevoegen',
        name: 'addCustomer',
        component: AddCustomer,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.commit('SET_CUSTOMER', null);
          await store.dispatch('LOAD_CUSTOMERS');
          next();
        },
      },
      {
        path: '*',
        redirect: '/cougarXr7/klanten',
      },
    ],
  },
  {
    path: '/cougarXr7/emails',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'emails',
        component: Emails,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.dispatch('LOAD_EMAILS');
          next();
        },
      },
      {
        path: 'bekijken/:id',
        name: 'viewEmail',
        component: ViewEmail,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_EMAIL', null);
            await store.dispatch('LOAD_EMAIL', to.params.id);
            await store.dispatch('LOAD_EMAILS');
            next();
          } catch (e) {
            toast.error(t('E-mail not found'));
            next({ name: 'reviews' });
          }
        },
      },
      {
        path: '*',
        redirect: '/cougarXr7/emails',
      },
    ],
  },
  {
    path: '/cougarXr7/emails-mobiel',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'emailsPhone',
        component: EmailsPhone,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.dispatch('LOAD_EMAILS_PHONE');
          next();
        },
      },
      {
        path: 'bekijken/:id',
        name: 'viewEmailPhone',
        component: ViewEmailPhone,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_EMAIL_PHONE', null);
            await store.dispatch('LOAD_EMAIL_PHONE', to.params.id);
            await store.dispatch('LOAD_EMAILS_PHONE');
            next();
          } catch (e) {
            toast.error(t('E-mail not found'));
            next({ name: 'reviews' });
          }
        },
      },
      {
        path: '*',
        redirect: '/cougarXr7/emails-mobiel',
      },
    ],
  },
  {
    path: '/cougarXr7/emails-hulp-op-afstand',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'emailsRemoteAssistance',
        component: EmailsRemoteAssistance,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.dispatch('LOAD_EMAILS_REMOTE_ASSISTANCE');
          next();
        },
      },
      {
        path: 'bekijken/:id',
        name: 'viewEmailRemoteAssistance',
        component: ViewEmailRemoteAssistance,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_EMAIL_REMOTE_ASSISTANCE', null);
            await store.dispatch('LOAD_EMAIL_REMOTE_ASSISTANCE', to.params.id);
            await store.dispatch('LOAD_EMAILS_REMOTE_ASSISTANCE');
            next();
          } catch (e) {
            toast.error(t('E-mail not found'));
            next({ name: 'reviews' });
          }
        },
      },
      {
        path: '*',
        redirect: '/cougarXr7/emails-hulp-op-afstand',
      },
    ],
  },
  {
    path: '/cougarXr7/producten',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'products',
        component: Products,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.dispatch('LOAD_PRODUCTS');
          next();
        },
      },
      {
        path: 'toevoegen',
        name: 'addProduct',
        component: AddEditProduct,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.commit('SET_PRODUCT', null);
          await store.commit('SET_PRODUCT_CHANGES', {});
          await store.dispatch('LOAD_NEXT_PRODUCT_ID');
          await store.dispatch('LOAD_PRODUCTS');
          next();
        },
      },
      {
        path: 'wijzigen/:id',
        name: 'editProduct',
        component: AddEditProduct,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_PRODUCT', null);
            await store.commit('SET_PRODUCT_CHANGES', {});
            await store.commit('SET_NEXT_PRODUCT_ID', null);
            await store.dispatch('LOAD_PRODUCT', to.params.id);
            await store.dispatch('LOAD_PRODUCTS');
            next();
          } catch (e) {
            toast.error(t('Product not found'));
            next({ name: 'products' });
          }
        },
      },
      {
        path: '*',
        redirect: '/cougarXr7/producten',
      },
    ],
  },
  {
    path: '/cougarXr7/preview/product/:id',
    name: 'previewProduct',
    component: ProductDetail,
    meta: { publicAccess: false, role: 'cms-admin' },
    async beforeEnter(to, from, next) {
      await store.dispatch('LOAD_PRODUCT', to.params.id);

      next();
    },
  },
  {
    path: '/cougarXr7/zzp-producten',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'zzpProducts',
        component: ZzpProducts,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.dispatch('LOAD_ZZP_PRODUCTS');
          next();
        },
      },
      {
        path: 'toevoegen',
        name: 'addZzpProduct',
        component: AddEditZzpProduct,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.commit('SET_ZZP_PRODUCT', null);
          await store.commit('SET_ZZP_PRODUCT_CHANGES', {});
          await store.dispatch('LOAD_NEXT_ZZP_PRODUCT_ID');
          await store.dispatch('LOAD_ZZP_PRODUCTS');
          next();
        },
      },
      {
        path: 'wijzigen/:id',
        name: 'editZzpProduct',
        component: AddEditZzpProduct,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_ZZP_PRODUCT', null);
            await store.commit('SET_ZZP_PRODUCT_CHANGES', {});
            await store.commit('SET_NEXT_ZZP_PRODUCT_ID', null);
            await store.dispatch('LOAD_ZZP_PRODUCT', to.params.id);
            await store.dispatch('LOAD_ZZP_PRODUCTS');
            next();
          } catch (e) {
            toast.error(t('Zzp product not found'));
            next({ name: 'zzpProducts' });
          }
        },
      },
      {
        path: '*',
        redirect: '/cougarXr7/zzp-producten',
      },
    ],
  },
  {
    path: '/cougarXr7/boekhouding/downloads/wijzigen/:id',
    name: 'editAccountantDownload',
    component: EditAccountantDownload,
    meta: { publicAccess: false, role: 'cms-admin' },
    async beforeEnter(to, from, next) {
      try {
        await store.commit('SET_ACCOUNTANT_DOWNLOAD', null);
        await store.commit('SET_ACCOUNTANT_DOWNLOAD_CHANGES', {});
        await store.dispatch('LOAD_ACCOUNTANT_DOWNLOAD', to.params.id);
        next();
      } catch (e) {
        toast.error(t('Accountant download not found'));
        next({ name: 'dashboard' });
      }
    },
  },
  {
    path: '/cougarXr7/nieuwe-pcs',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'statusesNewPc',
        component: StatusesNewPc,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.dispatch('LOAD_STATUSES_NEW_PC');
          next();
        },
      },
      {
        path: 'toevoegen',
        name: 'addStatusNewPc',
        component: AddEditStatusNewPc,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.commit('SET_STATUS_NEW_PC', null);
          await store.commit('SET_STATUS_NEW_PC_CHANGES', {});
          await store.dispatch('LOAD_NEXT_STATUS_NEW_PC_ID');
          await store.dispatch('LOAD_STATUSES_NEW_PC');
          await store.dispatch('LOAD_CUSTOMERS');
          next();
        },
      },
      {
        path: 'wijzigen/:id',
        name: 'editStatusNewPc',
        component: AddEditStatusNewPc,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_STATUS_NEW_PC', null);
            await store.commit('SET_STATUS_NEW_PC_CHANGES', {});
            await store.commit('SET_NEXT_STATUS_NEW_PC_ID', null);
            await store.dispatch('LOAD_STATUS_NEW_PC', to.params.id);
            await store.dispatch('LOAD_STATUSES_NEW_PC');
            await store.dispatch('LOAD_CUSTOMERS');
            next();
          } catch (e) {
            toast.error(t('Status new pc not found'));
            next({ name: 'statusesNewPc' });
          }
        },
      },
      {
        path: '*',
        redirect: '/cougarXr7/nieuwe-pcs',
      },
    ],
  },
  {
    path: '/cougarXr7/reparaties',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'statusesRepair',
        component: StatusesRepair,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.dispatch('LOAD_STATUSES_REPAIR');
          next();
        },
      },
      {
        path: 'toevoegen',
        name: 'addStatusRepair',
        component: AddEditStatusRepair,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.commit('SET_STATUS_REPAIR', null);
          await store.commit('SET_STATUS_REPAIR_CHANGES', {});
          await store.dispatch('LOAD_NEXT_STATUS_REPAIR_ID');
          await store.dispatch('LOAD_STATUSES_REPAIR');
          await store.dispatch('LOAD_CUSTOMERS');
          next();
        },
      },
      {
        path: 'wijzigen/:id',
        name: 'editStatusRepair',
        component: AddEditStatusRepair,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_STATUS_REPAIR', null);
            await store.commit('SET_STATUS_REPAIR_CHANGES', {});
            await store.commit('SET_NEXT_STATUS_REPAIR_ID', null);
            await store.dispatch('LOAD_STATUS_REPAIR', to.params.id);
            await store.dispatch('LOAD_STATUSES_REPAIR');
            await store.dispatch('LOAD_CUSTOMERS');
            next();
          } catch (e) {
            toast.error(t('Status repair not found'));
            next({ name: 'statusesRepair' });
          }
        },
      },
      {
        path: '*',
        redirect: '/cougarXr7/reparaties',
      },
    ],
  },
  {
    path: '/cougarXr7/nieuws-groepen',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'newsGroups',
        component: NewsGroupOverview,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
      },
      {
        path: 'toevoegen',
        name: 'addNewsGroup',
        component: AddEditNewsGroup,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.commit('SET_NEWS_GROUP', null);
          await store.commit('SET_NEWS_GROUP_CHANGES', {});
          await store.dispatch('LOAD_NEWS_GROUPS');
          next();
        },
      },
      {
        path: 'wijzigen/:id',
        name: 'editNewsGroup',
        component: AddEditNewsGroup,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_NEWS_GROUP', null);
            await store.commit('SET_NEWS_GROUP_CHANGES', {});
            await store.dispatch('LOAD_NEWS_GROUP', to.params.id);
            next();
          } catch (e) {
            toast.error(t('News group not found'));
            next({ name: 'newsGroups' });
          }
        },
      },
      {
        path: '*',
        redirect: '/cougarXr7/nieuws-groepen',
      },
    ],
  },
  {
    path: '/cougarXr7/nieuws-categorieen',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'newsCategories',
        component: NewsCategoryOverview,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.commit('SET_NEWS_CATEGORY', null);
          await store.commit('SET_NEWS_CATEGORY_CHANGES', {});
          await store.dispatch('LOAD_NEWS_CATEGORIES');
          next();
        },
      },
      {
        path: 'toevoegen',
        name: 'addNewsCategory',
        component: AddEditNewsCategory,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.commit('SET_NEWS_CATEGORY', null);
          await store.commit('SET_NEWS_CATEGORY_CHANGES', {});
          next();
        },
      },
      {
        path: 'wijzigen/:id',
        name: 'editNewsCategory',
        component: AddEditNewsCategory,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_NEWS_CATEGORY', null);
            await store.commit('SET_NEWS_CATEGORY_CHANGES', {});
            await store.dispatch('LOAD_NEWS_CATEGORY', to.params.id);
            next();
          } catch (e) {
            toast.error(t('News category not found'));
            next({ name: 'newsCategories' });
          }
        },
      },
      {
        path: '*',
        redirect: '/cougarXr7/nieuws-categorieen',
      },
    ],
  },
  {
    path: '/cougarXr7/service-groepen',
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    meta: { publicAccess: false, role: 'cms-amdin' },
    children: [
      {
        path: 'overzicht',
        name: 'serviceGroups',
        component: ServiceGroupOverview,
        meta: { isHomePage: true, publicAccess: false, role: 'cms-admin' },
      },
      {
        path: 'toevoegen',
        name: 'addServiceGroup',
        component: AddEditServiceGroup,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          await store.commit('SET_SERVICE_GROUP', null);
          await store.commit('SET_SERVICE_GROUP_CHANGES', {});
          await store.dispatch('LOAD_SERVICE_GROUPS');
          next();
        },
      },
      {
        path: 'wijzigen/:id',
        name: 'editServiceGroup',
        component: AddEditServiceGroup,
        meta: { publicAccess: false, role: 'cms-admin' },
        async beforeEnter(to, from, next) {
          try {
            await store.commit('SET_SERVICE_GROUP', null);
            await store.commit('SET_SERVICE_GROUP_CHANGES', {});
            await store.dispatch('LOAD_SERVICE_GROUP', to.params.id);
            next();
          } catch (e) {
            toast.error(t('Service group not found'));
            next({ name: 'serviceGroups' });
          }
        },
      },
      {
        path: '*',
        redirect: '/cougarXr7/service-groepen',
      },
    ],
  },

  // Routes Cms Status User
  {
    path: '/status',
    name: 'status',
    component: Status,
    meta: { publicAccess: false, role: 'cms-status-user' },
  },

  // Routes Cms ZZP
  {
    path: '/zzp',
    name: 'zzp',
    component: ZZP,
    meta: { publicAccess: false, role: 'cms-zzp' },
  },

  // Routes Cms ZZP
  {
    path: '/boekhouding',
    name: 'accountant',
    component: Accountant,
    meta: { publicAccess: false, role: 'cms-accountant' },
  },

  // News routes
  {
    path: '/nieuws',
    alias: ['/en/news', '/nl/nieuws'],
    name: 'newsOverview',
    component: NewsOverview,
    meta: { publicAccess: true },
    async beforeEnter(to, from, next) {
      await clearAll();
      await loadPage(to, next);

      next();
    },
  },
  {
    path: '/nieuws/:newsUrl',
    name: 'newsDetail',
    component: NewsDetail,
    meta: { publicAccess: true },
    async beforeEnter(to, from, next) {
      await clearAll();

      const fullPathWithoutLeadingSlash = to.params.newsUrl;

      const doesNewsItemExist = await store.dispatch('DOES_PUBLIC_NEWS_ITEM_EXIST', fullPathWithoutLeadingSlash);

      if (doesNewsItemExist) {
        await store.dispatch('LOAD_PUBLIC_NEWS_ITEM_BY_URL', fullPathWithoutLeadingSlash);
      } else {
        next({ name: 'pageNotFound' });
      }

      next();
    },
  },

  // Tips routes
  {
    path: '/tips-en-tricks',
    name: 'tipOverview',
    component: TipOverview,
    meta: { publicAccess: true },
    async beforeEnter(to, from, next) {
      await clearAll();
      await loadPage(to, next);

      next();
    },
  },
  {
    path: '/tips-en-tricks/:tipUrl',
    name: 'tipDetail',
    component: TipDetail,
    meta: { publicAccess: true },
    async beforeEnter(to, from, next) {
      await clearAll();

      const fullPathWithoutLeadingSlash = to.params.tipUrl;

      const doesTipExist = await store.dispatch('DOES_PUBLIC_TIP_EXIST', fullPathWithoutLeadingSlash);

      if (doesTipExist) {
        await store.dispatch('LOAD_PUBLIC_TIP_BY_URL', fullPathWithoutLeadingSlash);
      } else {
        next({ name: 'pageNotFound' });
      }

      next();
    },
  },

  // Product routes
  {
    path: '/verkoop/product-verkoop',
    alias: ['/en/sale/product-sale', '/nl/verkoop/product-verkoop'],
    name: 'productOverview',
    component: ProductOverview,
    meta: { publicAccess: true },
    async beforeEnter(to, from, next) {
      await clearAll();
      await loadPage(to, next);

      next();
    },
  },
  {
    path: '/verkoop/product-verkoop/:productUrl',
    alias: ['/en/sale/product-sale/:productUrl', '/nl/verkoop/product-verkoop/:productUrl'],
    name: 'productDetail',
    component: ProductDetail,
    meta: { publicAccess: true },
    async beforeEnter(to, from, next) {
      await clearAll();

      const fullPathWithoutLeadingSlash = to.params.productUrl;

      const doesTipExist = await store.dispatch('DOES_PUBLIC_PRODUCT_EXIST', fullPathWithoutLeadingSlash);

      if (doesTipExist) {
        await store.dispatch('LOAD_PUBLIC_PRODUCT_BY_URL', fullPathWithoutLeadingSlash);
      } else {
        next({ name: 'pageNotFound' });
      }

      next();
    },
  },

  // Page routes
  {
    path: '/pagina-niet-gevonden',
    name: 'pageNotFound',
    component: PageNotFound,
    meta: { publicAccess: true },
  },
  {
    path: '/contact',
    alias: ['/en/contact', '/nl/contact'],
    name: 'contactPage',
    component: ContactPage,
    meta: { publicAccess: true },
    async beforeEnter(to, from, next) {
      const currentLanguage = to.path.includes('/en/') || to.fullPath === '/en' ? 'en' : 'nl';
      const fullPathWithoutLeadingSlash = to.fullPath.includes('/en/') ? 'contact-en' : 'contact-nl';
      await clearAll();
      await checkAndLoadPage(fullPathWithoutLeadingSlash, next, currentLanguage);

      next();
    },
  },
  {
    path: '/:language/:path/:pathMatch(.*)*',
    name: 'subPage',
    component: Page,
    meta: { publicAccess: true },
    async beforeEnter(to, from, next) {
      await clearAll();
      await loadPage(to, next);

      next();
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page',
    component: Page,
    meta: {
      publicAccess: true,
    },
    async beforeEnter(to, from, next) {
      await clearAll();
      await loadPage(to, next);

      next();
    },
  },
];

const langRouterOptions = {
  defaultLanguage: 'nl',
  translations,
  localizedURLs,
};
const routerOptions = {
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return {
        selector: to.hash,
      };
    }

    return { left: 0, top: 0 };
  },
};

const router = createLangRouter(langRouterOptions, routerOptions);

router.beforeEach(async (to, from, next) => {
  if (to.name === 'error') {
    // eslint-disable-next-line no-console
    next();
    return;
  }

  const currentLanguage = to.path.includes('/en/') || to.fullPath === '/en' ? 'en' : 'nl';

  if (currentLanguage === 'nl' && !store.getters.PUBLIC_DUTCH_PAGES) {
    await store.dispatch('LOAD_PUBLIC_PAGES', currentLanguage);
  }

  if (currentLanguage === 'en' && !store.getters.PUBLIC_ENGLISH_PAGES) {
    await store.dispatch('LOAD_PUBLIC_PAGES', currentLanguage);
  }

  next();
});

router.beforeEach((to, from, next) => {
  if (!store.getters.NEWS_CATEGORIES) {
    store.dispatch('LOAD_NEWS_CATEGORIES');
  }

  if (!store.getters.NEWS_GROUPS) {
    store.dispatch('LOAD_NEWS_GROUPS');
  }

  if (!store.getters.SERVICE_GROUPS) {
    store.dispatch('LOAD_SERVICE_GROUPS');
  }

  if (to.meta.publicAccess) {
    // eslint-disable-next-line no-console
    next();
    return;
  }

  // redirect to login page when cms user is not logged in
  if (!store.getters.SECURE_API_USER) {
    if (to.meta.role === 'cms-admin') {
      next({ name: 'login-cms-admin', query: { redirect: to.path } });
      return;
    }

    if (to.meta.role === 'cms-status-user') {
      next({ name: 'login-cms-status-user', query: { redirect: to.path } });
      return;
    }

    if (to.meta.role === 'cms-zzp') {
      next({ name: 'login-cms-zzp', query: { redirect: to.path } });
      return;
    }

    if (to.meta.role === 'cms-accountant') {
      next({ name: 'login-cms-accountant', query: { redirect: to.path } });
      return;
    }

    next({ path: '/' });
    return;
  }

  // Check if user has the right role to have acces to a specific route
  if (store.getters.SECURE_API_USER) {
    const roleRoute = to.meta.role;
    const userRole = store.getters.SECURE_API_USER.user.role;

    if (roleRoute !== userRole) {
      const dashboardRoute =
        userRole === 'cms-accountant'
          ? { name: 'accountant' }
          : userRole === 'cms-status-user'
          ? { name: 'status' }
          : userRole === 'cms-zzp'
          ? { name: 'zzp' }
          : userRole === 'cms-admin'
          ? { name: 'cms' }
          : { path: '/' };

      toast.error(
        t('You do not have permission to view this page. The requested page requires proper authorization for access.')
      );

      next(dashboardRoute);
    }
  }

  next();
});

export default router;
