import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

const axiosOptions = {
  // baseURL: 'http://localhost:8000/api/',
  baseURL: 'https://www.freshitsolutions.nl/api/',
  withCredentials: true,
  timeout: 15000,
};

const apiAxios = axios.create(axiosOptions);

apiAxios.interceptors.request.use((config) => {
  const configuration = config;
  // add _format=json to every request
  configuration.params = {
    ...{ _format: 'json' },
    ...(config.method === 'get' && { ts: Date.now().toString() }),
    ...config.params,
  };

  return configuration;
});

let CSRFToken;
const getCSRFToken = () => apiAxios.get('csrf-cookie');

const apiAxiosWithCSRF = axios.create(axiosOptions);

apiAxiosWithCSRF.interceptors.request.use((config) => {
  const configuration = config;

  // add _format=json to every request
  configuration.params = {
    ...{ _format: 'json' },
    ...config.params,
  };

  // add CSRF token to header
  configuration.headers['X-CSRF-Token'] = CSRFToken;

  return configuration;
});

const requestWithCSRFToken =
  (method) =>
  (...args) =>
    getCSRFToken().then(() => apiAxios[method](...args));

const api = {
  ...apiAxios,
  ...{
    post: requestWithCSRFToken('post'),
    patch: requestWithCSRFToken('patch'),
    put: requestWithCSRFToken('put'),
    delete: requestWithCSRFToken('delete'),
  },
};

const listeners = [];
export const addListener = (listener) => listeners.push(listener);

export default Object.keys(api).reduce(
  (result, method) => ({
    ...result,
    [method]: (...args) => {
      const promise = api[method](...args);
      listeners.forEach((listener) => listener(promise));
      return promise;
    },
  }),
  {}
);
